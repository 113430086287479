import { createSlice } from '@reduxjs/toolkit';
const initialState = { isAll: true, isIncome: false };
export const pagesStateSlice = createSlice({
  name: 'pagesState',
  initialState,
  reducers: {
    changeAll(state, action) {
      state.isAll = action.payload;
    },
    changeIsIncome(state, action) {
      state.isIncome = action.payload;
    },
  },
});

export const { changeAll, changeIsIncome } = pagesStateSlice.actions;

export const pagesStateReducer = pagesStateSlice.reducer;
