import { Rings } from 'react-loader-spinner';
import { LoaderWrap } from './Loader.styled';
import { ModalBackdrop } from 'components/Modal/ModalLogout/ModalLogout.styled';

export const Loader = () => {
  return (
    <ModalBackdrop style={{ backdropFilter: 'blur(5px)' }}>
      <LoaderWrap>
        <Rings
          height="80"
          width="80"
          color="#FF7601"
          secondaryColor="#FF7601"
          radius="6"
          ariaLabel="rings-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      </LoaderWrap>
    </ModalBackdrop>
  );
};
