export const optionsLang = [
  {
    value: 'en',
    label: 'English',
  },
  {
    value: 'uk',
    label: 'Українська',
  },
  {
    value: 'ru',
    label: 'Русский',
  },
];

export const optionsCar = [
  {
    value: 'eur',
    label: 'EUR',
    symbol: '€',
  },
  {
    value: 'usd',
    label: 'USD',
    symbol: '$',
  },
  {
    value: 'UAH',
    label: 'UAH',
    symbol: '₴',
  },
];

export const LOCALS = {
  UK: "uk",
  EN: "en",
  RU: 'ru'
}