import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { Notify } from 'notiflix/build/notiflix-notify-aio';
import { getPeriod } from 'utils/getPeriod';

export const addReminder = createAsyncThunk(
  'remainders/addReminder',
  async (
    { category, description, sum, dateOfPayment, icon, regularPayment },
    ThunkAPI
  ) => {
    try {
      const { data } = await axios.post('/remainders', {
        category,
        description,
        sum,
        dateOfPayment,
        icon,
        regularPayment,
      });
      return data;
    } catch (e) {
      Notify.warning(e.response.data.message);
      return ThunkAPI.rejectWithValue(e.message);
    }
  }
);
export const getReminders = createAsyncThunk(
  'remainders/getSortedReminders',
  async ({ dateRange }, ThunkAPI) => {
    try {
      const [start, end] = getPeriod(dateRange);

      const { data } = await axios.get(`/remainders/sort`, {
        params: {
          startDate: start,
          endDate: end,
        },
      });
      return data;
    } catch (e) {
      Notify.warning(e.response.data.message || 'An error occurred');
      return ThunkAPI.rejectWithValue(e.message);
    }
  }
);
export const deleteReminder = createAsyncThunk(
  'remainders/deleteReminder',
  async ({ transactionId }, ThunkAPI) => {
    try {
      const { data } = await axios.delete(`/remainders/${transactionId}`);
      return data;
    } catch (e) {
      Notify.warning(e.response.data.message);
      return ThunkAPI.rejectWithValue(e.message);
    }
  }
);
export const editReminder = createAsyncThunk(
  'remainders/editReminder',
  async ({ transactionId, updatedData }, ThunkAPI) => {
    try {
      const { data } = await axios.put(
        `/remainders/${transactionId}`,
        updatedData
      );
      return data;
    } catch (e) {
      Notify.warning(e.response.data.message);
      return ThunkAPI.rejectWithValue(e.message);
    }
  }
);
