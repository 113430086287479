import styled from 'styled-components';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';

export const ButtonCalendar = styled.button`
  border: none;
  background-color: ${p => p.theme.colors.main.white};
  cursor: pointer;
`;

export const Wrap = styled.div`
  z-index: 100;
  position: sticky;
  top: 64px;
  background-color: #fff;
  display: flex;
  border-radius: 30px;
  box-shadow: 5px 10px 20px rgba(170, 178, 197, 0.4);
  text-align: center;
  padding: 7px 23px;
  box-sizing: border-box;
  input {
    font-family: 'Kulim Park';
    font-weight: 700;
    font-size: 12px;
    line-height: 1.5;
    color: ${p => p.theme.colors.main.orangeMain};
    display: flex;
    align-items: center;
    text-align: center;
    width: 100%;
    background-color: transparent;
    border: none;
    outline: none;
  }
  input::placeholder {
    color: ${p => p.theme.colors.main.orangeMain};
  }
  .react-datepicker-popper[data-placement^='bottom'] {
    inset: 0% 5% 0% -5% !important;
    padding-top: 29px;
  }
  .react-datepicker__month-container {
    float: none;
    padding-top: 13px;
    padding-left: 4px;
    padding-right: 4px;
    box-sizing: border-box;
  }
  .react-datepicker__month {
    margin: 0;
  }
  .react-datepicker__week {
    margin: 0;
  }
  .react-datepicker__current-month {
    font-family: 'Kulim Park';
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 16px;
    line-height: 1.5;
    color: #ff7601;
  }

  .react-datepicker__navigation-icon::before {
    border-color: #ff7601;
    border-width: 2px 2px 0 0;
    border-radius: 2px;
    top: 9px;
    height: 7px;
    width: 7px;
  }
  .react-datepicker__navigation--previous {
    left: 42px;
  }
  .react-datepicker__navigation--next {
    right: 42px;
  }
  .react-datepicker__navigation {
    top: 13px;
    height: 24px;
    width: 24px;
  }
  .react-datepicker {
    font-family: 'Kulim Park';
    font-size: 12px;
    width: 100%;
    height: auto;
    background-color: #ffffff;
    border: none;
    box-shadow: 4px 4px 16px rgba(170, 178, 197, 0.25);
    border-radius: 16px;
  }
  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    font-size: 12px;
    line-height: 3.33;
    color: #ff7601;
    display: inline-block;
    text-align: center;
    margin: 0;
    width: 40px;
    height: 40px;
  }
  .react-datepicker__day-names {
    margin-bottom: 0px;
  }
  .react-datepicker__day--disabled,
  .react-datepicker__month-text--disabled,
  .react-datepicker__quarter-text--disabled,
  .react-datepicker__year-text--disabled {
    cursor: default;
    color: #ff7601;
    opacity: 0.5;
  }
  .react-datepicker__day--outside-month {
    color: #ff7601;
    opacity: 0.5;
  }
  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range,
  .react-datepicker__day--keyboard-selected {
    border-radius: 50%;
    background-color: #ff7601;
    color: #fff;
  }
  .react-datepicker__header {
    text-align: center;
    background-color: #ffffff;
    border-bottom: 1px solid rgba(255, 118, 1, 0.5);
    margin: 0;
    font-weight: 700;
    padding: 0;
  }
  .react-datepicker__children-container {
    display: flex;
    justify-content: flex-end;
    width: 84%;
    margin: 0;
    padding-left: 8px;
    padding-right: 8px;
    height: auto;
  }
`;

export const LineWraper = styled.div`
  margin-left: 16px;
  margin-right: 16px;
  z-index: 10;
`;
export const Switcher = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const BtnArrow = styled.button`
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: transparent;
  border: none;
  transition: transform 300ms cubic-bezier(0.4, 0, 0.2, 1);

  &:focus {
    transform: scale(0.9);
  }
`;

export const Buttons = styled.button`
  border: none;
  font-size: 12px;
  font-weight: 700;
  line-height: 1.5;
  color: #ff7601;
  padding: 11px;
  background-color: inherit;
  cursor: pointer;
`;

export const Title = styled.p`
  position: absolute;
  font-family: 'Kulim Park';
  font-weight: 700;
  font-size: 12px;
  line-height: 1.5;
  color: ${p => p.theme.colors.main.orangeMain};
`;
///////////////////////////////////Calendar small
export const CalendarWrap = styled.div`
  display: flex;
  gap: 9px;
`;
export const SmallButton = styled.button`
  width: 40px;
  height: 40px;
  border: 1px solid #fff1e5;
  border-radius: 50%;
  background-color: #ffffff;
`;

export const SmallCalWrap = styled.div`
  padding: 8px 16px;
  background: #fff1e5;
  border-radius: 20px;
  input {
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 16px;
    line-height: 1.5;
    color: ${p => p.theme.colors.main.orangeMain};
    width: 84px;
    text-align: center;
    background-color: transparent;
    border: none;
    outline: none;
  }
  input::placeholder {
    color: ${p => p.theme.colors.main.orangeMain};
  }
  .react-datepicker-popper[data-placement^='bottom'] {
    inset: 0% 5% 0% -5% !important;
    padding-top: 25px;
    width: calc(100% - 32px);
    margin-left: 5px;
  }
  .react-datepicker__month-container {
    float: none;
    padding-top: 13px;
    padding-left: 4px;
    padding-right: 4px;
    box-sizing: border-box;
  }
  .react-datepicker__month {
    margin: 0;
  }
  .react-datepicker__week {
    margin: 0;
  }
  .react-datepicker__current-month {
    font-family: 'Kulim Park';
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 16px;
    line-height: 1.5;
    color: #ff7601;
  }

  .react-datepicker__navigation-icon::before {
    border-color: #ff7601;
    border-width: 2px 2px 0 0;
    border-radius: 2px;
    top: 9px;
    height: 7px;
    width: 7px;
  }
  .react-datepicker__navigation--previous {
    left: 42px;
  }
  .react-datepicker__navigation--next {
    right: 42px;
  }
  .react-datepicker__navigation {
    top: 13px;
    height: 24px;
    width: 24px;
  }
  .react-datepicker {
    font-family: 'Kulim Park';
    font-size: 12px;
    width: 100%;
    height: auto;
    background-color: #ffffff;
    border: none;
    box-shadow: 4px 4px 16px rgba(170, 178, 197, 0.25);
    border-radius: 16px;
  }
  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    font-size: 12px;
    line-height: 3.33;
    color: #ff7601;
    display: inline-block;
    text-align: center;
    margin: 0;
    width: 40px;
    height: 40px;
  }
  .react-datepicker__day-names {
    margin-bottom: 0px;
  }
  .react-datepicker__day--disabled,
  .react-datepicker__month-text--disabled,
  .react-datepicker__quarter-text--disabled,
  .react-datepicker__year-text--disabled {
    cursor: default;
    color: #ff7601;
    opacity: 0.5;
  }
  .react-datepicker__day--outside-month {
    color: #ff7601;
    opacity: 0.5;
  }
  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range,
  .react-datepicker__day--keyboard-selected {
    border-radius: 50%;
    background-color: #ff7601;
    color: #fff;
  }
  .react-datepicker__header {
    text-align: center;
    background-color: #ffffff;
    border-bottom: 1px solid rgba(255, 118, 1, 0.5);
    margin: 0;
    font-weight: 700;
    padding: 0;
  }
  .react-datepicker__children-container {
    display: flex;
    justify-content: flex-end;
    width: calc(100% - 20px);
    margin: 0px 0px 5px;
    padding-left: 8px;
    padding-right: 8px;
    height: auto;
  }
  .react-datepicker__input-time-container {
    text-align: center;
    width: 80%;
  }
  .react-datepicker__input-time-container .react-datepicker-time__caption {
    color: #ff7601;
    font-size: 14px;
  }
  .react-datepicker__time-name {
    color: #ff7601;
  }
  ///////////////////////////////////////////
`;
export const CalOrangeBtn = styled(SmallButton)`
  background-color: #fff1e5;
  border: 1px solid #ffffff;
`;
export const CalOrangeWrap = styled(SmallCalWrap)`
  background: #fff;
`;
export const CalClockWrap = styled(SmallCalWrap)`
  input {
    width: 100%;
  }
`;

////////////////////////////////////////////Search ///////////////////////
export const SearchWrap = styled.div`
  display: flex;
  input {
    font-size: 12px;
    color: #52555f;
    border: none;
    border-radius: 30px;
    width: 100%;
    text-align: right;
    padding: 14px;
    background: #fff1e5;
  }
  input:focus {
    outline-offset: 0px;
    outline: none;
  }
`;

export const IconDescr = styled.p`
  display: flex;
  font-family: 'Kulim Park';
  font-size: 10px;
  line-height: 1.5;
  color: ${p => p.theme.colors.main.orangeMain};
  margin: 0;
`;

export const IconWraper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: 8px;
`;
