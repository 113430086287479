import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { LOCALS } from './data';
import uk from './translate/ua/ua.json';
import en from './translate/en/en.json';
import ru from './translate/ru/ru.json';

const resources = {
  [LOCALS.UK]: { translation: uk },
  [LOCALS.EN]: { translation: en },
  [LOCALS.RU]: { translation: ru },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: LOCALS.EN,
    interpolation: {
      escapeValue: false,
    },
  });
