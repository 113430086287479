import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { Notify } from 'notiflix/build/notiflix-notify-aio';

export const newWallet = createAsyncThunk(
  'wallets/newWallet',
  async ({ name, icon, description }, ThunkAPI) => {
    try {
      const { data } = await axios.post('/wallets', {
        name,
        icon,
        description,
      });
      return data;
    } catch (e) {
      Notify.warning(e.response.data.message);
      return ThunkAPI.rejectWithValue(e.message);
    }
  }
);

export const deleteWallet = createAsyncThunk(
  'wallets/deleteWallet',
  async ({ walletId }, ThunkAPI) => {
    try {
      const { data } = await axios.delete(`/wallets/${walletId}`);
      return data;
    } catch (e) {
      Notify.warning(e.response.data.message);
      return ThunkAPI.rejectWithValue(e.message);
    }
  }
);

export const getWallets = createAsyncThunk(
  'wallets/getWallets',
  async (_, ThunkAPI) => {
    try {
      const { data } = await axios.get(`/wallets`);
      return data;
    } catch (e) {
      Notify.warning(e.response.data.message);
      return ThunkAPI.rejectWithValue(e.message);
    }
  }
);

export const editWallet = createAsyncThunk(
  'wallets/editWallet',
  async ({ walletId, updatedData }, ThunkAPI) => {
    try {
      const { data } = await axios.put(`/wallets/${walletId}`, updatedData);
      return data;
    } catch (e) {
      Notify.warning(e.response.data.message);
      return ThunkAPI.rejectWithValue(e.message);
    }
  }
);
