import React, { useState, useEffect } from 'react';
import { groupBy } from 'lodash';
import { EditDeletBtns } from 'components/Modal/ModalTransaction/EditDelBtns/EditDeletBtns';
import { ModalTransactions } from 'components/Modal/ModalTransaction/ModalTransaction';
import {
  CategoryStyle,
  DescriptionStyle,
  Item,
  WrapperText,
  WrapSvgText,
  Sum,
  Plug,
  DateTransaction,
  LocationUser,
  ImgLocation,
  SelectedItem,
  PlugText,
  TableHeading,
} from './UniversalTransactions.styled';
import locationIcon from 'images/location.svg';
import flowers from 'images/flowers.png';
import Icons from 'images/allSvgFinTrack.svg';
import { ModalReminders } from 'components/Modal/ModalReminder/ModalReminder';
import { useSelector } from 'react-redux';
import { selectAllWallet, selectUserCurrency } from 'redux/selectors';
import { useTranslation } from 'react-i18next';
import { DebtsItemUniversal } from './DebtsItemUniversal';
import { useTranslate } from 'hooks/useTranslate';

import ModalPaidStatus from 'components/Modal/ModalDebt/ModalPaidStatus';

export const UniversalTransactions = ({
  filteredTransactions,
  getColorAndIcon,
  selectedTransactionId,
  setSelectedTransactionId,
  transactions,
  isBlurApplied,
  setIsBlurApplied,
  allTransactions,
  tableHeadingSelect,
  deleteType,
  paidStatusMarker,
}) => {
  const [modalEditOpen, setModalEditOpen] = useState(false);
  const [transactionToEdit, setTransactionToEdit] = useState(null);
  const [transactionsCheck, setTransactionsCheck] = useState(false);
  const [modalDebtEditOpen, setModalDebtEditOpen] = useState(false);
  const allWallets = useSelector(selectAllWallet);
  const currencySymbol = useSelector(selectUserCurrency);
  const {
    titleRemainders,
    titleDebts,
    monthTrsl,
    daysTrsl,
    category,
    debtsIcon,
  } = useTranslate();
  const { t } = useTranslation();

  useEffect(() => {
    if (transactions.length === 0) {
      return setTransactionsCheck(false);
    } else {
      return setTransactionsCheck(true);
    }
  }, [transactions]);

  const sortedTransactions = paidStatusMarker
    ? [...filteredTransactions].sort(
        (a, b) => new Date(a.date) - new Date(b.date)
      )
    : [...filteredTransactions].sort(
        (a, b) => new Date(b.date) - new Date(a.date)
      );

  // const handleTransactionClick = (transactionId, event) => {
  //   console.log('transactionId', transactionId);
  //   if (selectedTransactionId === transactionId) {
  //     event.preventDefault();
  //     setSelectedTransactionId(null);
  //     setIsBlurApplied(false);
  //   } else {
  //     setSelectedTransactionId(transactionId);
  //     setIsBlurApplied(true);
  //   }
  // };
  const handleTransactionClick = (transactionId, event) => {
    if (selectedTransactionId === transactionId) {
      event.preventDefault();
      setSelectedTransactionId(null);
      setIsBlurApplied(false);
    } else {
      setSelectedTransactionId(transactionId);
      setIsBlurApplied(true);
    }
  };

  const groupedTransactions = groupBy(sortedTransactions, transaction => {
    const dateObj = new Date(transaction.date || transaction.createdAt);
    const month = dateObj.toLocaleDateString('en-US', { month: 'long' });
    const translatedMonth = monthTrsl[month];
    const translatedDayOfWeek =
      daysTrsl[dateObj.toLocaleDateString('en-US', { weekday: 'long' })];
    const dayOfMonth = dateObj.getDate();
    const fullDate = dateObj.toLocaleDateString('en-US', {
      year: 'numeric',
    });
    return `${translatedDayOfWeek}, ${dayOfMonth}, ${translatedMonth},  ${fullDate}`;
  });

  const handleEditClick = transaction => {
    if (deleteType === 'debt') {
      console.log('deleteType', deleteType);

      setModalDebtEditOpen(true);
      setTransactionToEdit(transaction);

      setModalEditOpen(false);
    } else {
      setTransactionToEdit(transaction);
      setModalEditOpen(true);
      setModalDebtEditOpen(false);
    }
  };
  const findWalletName = (data, id, Wallets) => {
    const matchingData = data.find(obj => obj.walletId === id);

    if (matchingData) {
      const matchingWallet = Wallets.find(
        wallet => wallet._id === matchingData.walletId
      );

      if (matchingWallet) {
        return matchingWallet.name;
      }
    }

    return 'Unknown wallet';
  };

  return (
    <>
      {modalEditOpen &&
        transactionToEdit &&
        (tableHeadingSelect !== true ? (
          <ModalTransactions
            onClose={() => {
              setModalEditOpen(false);
              setTransactionToEdit(null);
              setModalDebtEditOpen(false);
            }}
            transactionToEdit={transactionToEdit}
          />
        ) : (
          <ModalReminders
            onClose={() => {
              setModalEditOpen(false);
              setTransactionToEdit(null);
              setModalDebtEditOpen(false);
            }}
            transactionToEdit={transactionToEdit}
          />
        ))}
      {modalDebtEditOpen && (
        <ModalPaidStatus
          debt={transactionToEdit}
          onClose={() => {
            setModalDebtEditOpen(false);
          }}
        />
      )}
      {allTransactions ? (
        <TableHeading>{t('tableTrans.title')}</TableHeading>
      ) : (
        <TableHeading>
          {tableHeadingSelect ? titleRemainders : titleDebts}
        </TableHeading>
      )}

      {transactionsCheck ? (
        <ul>
          {Object.keys(groupedTransactions).map(date => (
            <React.Fragment key={date}>
              <DateTransaction>{date}</DateTransaction>
              {groupedTransactions[date].map(transaction => {
                const { color, position } = getColorAndIcon(
                  transaction.operation
                );

                const isTransactionSelected =
                  selectedTransactionId === transaction._id;
                const TransactionItem = isTransactionSelected
                  ? SelectedItem
                  : Item;

                return (
                  <React.Fragment key={transaction._id}>
                    {deleteType === 'debt' ? (
                      <TransactionItem
                        onClick={event =>
                          handleTransactionClick(transaction._id, event)
                        }
                        isTransactionSelected={isTransactionSelected}
                        style={
                          isBlurApplied && !isTransactionSelected
                            ? { filter: 'blur(2px)' }
                            : {}
                        }
                      >
                        <DebtsItemUniversal
                          transaction={transaction}
                          Icons={Icons}
                          deleteType={deleteType}
                          color={color}
                          position={position}
                          selectedTransactionId={selectedTransactionId}
                          handleEditClick={handleEditClick}
                          tableHeadingSelect={tableHeadingSelect}
                          paidStatusMarker={paidStatusMarker}
                          setModalDebtEditOpen={setModalDebtEditOpen}
                          onClose={() => {
                            setModalDebtEditOpen(false);
                            setModalEditOpen(false);
                          }}
                        />
                      </TransactionItem>
                    ) : (
                      <TransactionItem
                        onClick={event =>
                          handleTransactionClick(transaction._id, event)
                        }
                        isTransactionSelected={isTransactionSelected}
                        style={
                          isBlurApplied && !isTransactionSelected
                            ? { filter: 'blur(2px)' }
                            : {}
                        }
                      >
                        <WrapSvgText>
                          <svg width={24} height={24} fill={'#FF7601'}>
                            <use href={`${Icons}#${transaction.icon}`} />
                          </svg>

                          <WrapperText>
                            <CategoryStyle
                              text={
                                category[transaction.icon]
                                  ? category[transaction.icon].categoryName
                                  : debtsIcon[transaction.icon]
                                  ? debtsIcon[transaction.icon].categoryName
                                  : ''
                              }
                              length={14}
                            />
                            {transaction.walletId === undefined ? (
                              <svg width={24} height={24} fill={'#FF7601'}>
                                <use
                                  href={`${Icons}#${
                                    getColorAndIcon(transaction.operation).icon
                                  }`}
                                />
                              </svg>
                            ) : (
                              <DescriptionStyle
                                text={findWalletName(
                                  transactions,
                                  transaction.walletId,
                                  allWallets
                                )}
                                length={10}
                              />
                            )}

                            <DescriptionStyle
                              text={transaction.description}
                              length={20}
                            />
                            {transaction.operation === undefined ? (
                              ''
                            ) : (
                              <LocationUser>
                                <ImgLocation
                                  src={locationIcon}
                                  alt="locationIcon"
                                />
                              </LocationUser>
                            )}
                          </WrapperText>
                        </WrapSvgText>
                        <Sum
                          style={{
                            color: color,
                          }}
                        >
                          {position} {currencySymbol}{' '}
                          {transaction.sum.toFixed(0)}
                        </Sum>
                        {selectedTransactionId === transaction._id && (
                          <EditDeletBtns
                            data={transaction}
                            transactionId={transaction._id}
                            selectedTransactionId={selectedTransactionId}
                            handleEditClick={handleEditClick}
                            tableHeadingSelect={tableHeadingSelect}
                            deleteType={deleteType}
                            setModalDebtEditOpen={setModalDebtEditOpen}
                            paidStatusMarker={paidStatusMarker}
                          />
                        )}
                      </TransactionItem>
                    )}
                  </React.Fragment>
                );
              })}
            </React.Fragment>
          ))}
        </ul>
      ) : (
        <Plug key="no-transaction">
          <PlugText>
            {t('tableTrans.plugTextOne')} <br />
            {t('tableTrans.plugTextTwo')}
          </PlugText>
          <svg
            width="20"
            height="20"
            style={{
              position: 'absolute',
              top: '74px',
              right: '166px',
            }}
          >
            <use href={`${Icons}#icon-light-gear`}></use>
          </svg>
          <svg
            width="28"
            height="28"
            style={{
              position: 'absolute',
              top: '112px',
              right: '17px',
            }}
          >
            <use href={`${Icons}#icon-light-gear`}></use>
          </svg>
          <svg
            width="28"
            height="28"
            style={{
              position: 'absolute',
              top: '190px',
              left: '4px',
            }}
          >
            <use href={`${Icons}#icon-light-gear`}></use>
          </svg>
          <img src={flowers} alt="flowers" style={{ marginTop: '24px' }} />
        </Plug>
      )}
    </>
  );
};
