import styled from 'styled-components';
import { WalletSelect } from 'components/Modal/ModalTransaction/ModalTransaction.styled';

export const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.25);
`;

export const ModalContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80%;
  padding: 24px;
  background-color: #fff;
  border-radius: 30px;
  box-shadow: 2px 4px 20px rgba(170, 178, 197, 0.25);
  transform: translate(-50%, -50%);
  box-sizing: border-box;
`;

export const CloseBtn = styled.button`
  position: absolute;
  right: 16px;
  top: 16px;
  background-color: transparent;
  border: none;
  cursor: pointer;

  transition: 250ms;

  :hover {
    transform: scale(1.2);
  }
`;

export const TitleText = styled.p`
  font-size: 12px;
  line-height: 1.5;
  text-align: center;
  color: #52555f;
  letter-spacing: 0.04em;
  margin-left: 5px;
`;

export const BtnsContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const ConfirmBtn = styled.button`
  width: 130px;
  height: 42px;

  border: none;
  border-radius: 16px;
  cursor: pointer;
  font-size: 12px;
  line-height: 1.5;
  text-align: center;
  letter-spacing: 0.02em;

  color: #fff;
  background-color: #ff751d;
  box-shadow: 4px 4px 16px rgba(255, 107, 8, 0.25);

  transition-property: transform;
  transition-duration: 250ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

  :hover {
    transform: scale(1.1);
  }
`;
export const HeaderWrap = styled(BtnsContainer)`
  align-items: center;
  margin-bottom: 24px;
`;
export const Label = styled.label`
  font-size: 10px;
  line-height: 1.5;
  letter-spacing: 0.04em;
  color: #52555f;
  margin-bottom: 4px;
`;
export const Input = styled.input`
  color: #52555f;
  border: none;
  font-size: 12px;
  line-height: 1.5;
  background: #fff1e5;
  border-radius: 30px;
  border: 1px solid transparent;
  width: 100%;
  padding: 11px 16px;
  box-sizing: border-box;
  ::placeholder {
    color: #a6abb9;
  }
  :focus {
    outline: #ff7601;
    border: 1px solid #ff7601;
  }
`;
export const InputWrap = styled.div`
  margin-bottom: 24px;
`;
export const LanguageSelect = styled(WalletSelect)`
  .wallet-select__single-value {
    justify-content: flex-start;
  }
  .wallet-select__placeholder {
    text-align: start;
    margin: 0;
  }
  .wallet-select__control {
    margin-bottom: 24px;
    margin-top: 0;
    box-shadow: 4px 4px 16px rgba(170, 178, 197, 0.25);
  }
  .wallet-select__value-container {
    padding-left: 12px;
  }
`;
export const CurrencySelect = styled(LanguageSelect)``;
