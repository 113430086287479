import { useState } from 'react';
import {
  FooterList,
  FooterContainer,
  FooterText,
  FooterItem,
  FooterItemPlus,
  Styledlink,
  BtnPlus,
} from './Footer.styled';
import FooterSvg from 'images/allSvgFinTrack.svg';
import { ModalTransactions } from 'components/Modal/ModalTransaction/ModalTransaction';
import { useSelector } from 'react-redux';
import { selectIsLoggedIn } from 'redux/selectors';
import { useTranslation } from 'react-i18next';

export default function Footer() {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const [modalOpen, setModalOpen] = useState(false);
  const toggleModal = () => {
    setModalOpen(prevState => !prevState);
  };
  const { t } = useTranslation();
  
  return (
    <>
      {isLoggedIn && (
        <>
          <FooterContainer>
            <FooterList>
              <FooterItem style={{ marginRight: 24 }}>
                <Styledlink to="/home">
                  <svg width={24} height={24} fill={'#FF7601'}>
                    <use href={`${FooterSvg}#home`} />
                  </svg>
                  <FooterText>{t('pageName.home')}</FooterText>
                </Styledlink>
              </FooterItem>
              <FooterItem style={{ marginRight: 'auto' }}>
                <Styledlink to="/reports">
                  <svg width={24} height={24} fill={'#FF7601'}>
                    <use href={`${FooterSvg}#report`} />
                  </svg>
                  <FooterText>{t('pageName.report')}</FooterText>
                </Styledlink>
              </FooterItem>
              <FooterItemPlus>
                <BtnPlus onClick={toggleModal}>
                  <svg width={24} height={24} fill={'#FFffff'}>
                    <use href={`${FooterSvg}#plus`} />
                  </svg>
                </BtnPlus>
              </FooterItemPlus>
              <FooterItem style={{ marginLeft: 'auto' }}>
                <Styledlink to="/planning">
                  <svg width={24} height={24} fill={'#FF7601'}>
                    <use href={`${FooterSvg}#planning`} />
                  </svg>
                  <FooterText>{t('pageName.planning')}</FooterText>
                </Styledlink>
              </FooterItem>
              <FooterItem style={{ marginLeft: 24 }}>
                <Styledlink to="/wallets" style={{ marginLeft: 'auto' }}>
                  <svg width={24} height={24} fill={'#FF7601'}>
                    <use href={`${FooterSvg}#wallets`} />
                  </svg>
                  <FooterText>{t('pageName.wallets')}</FooterText>
                </Styledlink>
              </FooterItem>
            </FooterList>
          </FooterContainer>
          {modalOpen && <ModalTransactions onClose={toggleModal} />}
        </>
      )}
    </>
  );
}
