import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addTransaction,
  editTransaction,
} from 'redux/transactions/transactionsOperations';

import Notiflix from 'notiflix';
import {
  BtnWrap,
  Input,
  ImgLocation,
  Label,
  WalletSelect,
} from '../ModalTransaction.styled';

import { Calculator } from '../Calculator/Calculator';

import StyledButton from '../../../Buttons/Buttons.styled';
import { selectAllWallet } from 'redux/selectors';
import { Category } from '../Category/Category';
import { Calendar } from 'components/Calendar/Calendar';
import { SwitchOperation } from '../SwichOperation/SwichOperation';
import { Display } from '../Calculator/Display';
import locationIcon from 'images/location.svg';
import { getWallets } from 'redux/wallets/walletsOperations';
import { useTranslation } from 'react-i18next';
import { useTranslate } from 'hooks/useTranslate';

export const AddTransaction = ({
  onChangeTagInput,
  onClose,
  transactionToEdit,
}) => {
  const dispatch = useDispatch();
  const { operationsSwitch } = useTranslate();
   const { t } = useTranslation();
  const transaction = transactionToEdit || {};
  const [description, setDescription] = useState(
    transaction?.description || ''
  );
  const [date, setDate] = useState(
    transaction?.date ? new Date(transaction.date) : new Date()
  );
  const [operation, setOperation] = useState(
    transaction?.operation || operationsSwitch[0]
  );
  const [place, setPlace] = useState(transaction?.place || '');
  const [wallet, setWallet] = useState(
    transaction?.walletId
      ? { label: transaction?.walletId.name, value: transaction?.walletId._id }
      : ''
  );
  const [input, setInput] = useState(transaction?.sum || '');
  const [answer, setAnswer] = useState(transaction?.sum || '');
  const [openCalculator, setOpenCalculator] = useState(false);

  const [icon, setIcon] = useState(transaction?.icon || '');
  let sum = transaction?.sum || Number(answer) || Number(input);

  const [selectedCategory, setSelectedCategory] = useState(
    transaction?.icon || null
  );
  const walletOptions = useSelector(selectAllWallet);

  useEffect(() => {
    if (transactionToEdit) {
      setOperation(
        operationsSwitch.find(op => op.value === transactionToEdit.operation)
      );
    }
  }, [transactionToEdit, operationsSwitch]);

  const handleSubmit = async e => {
    e.preventDefault();

    if (
      description === '' ||
      sum === '' ||
      wallet === '' ||
      selectedCategory === ''
    ) {
      Notiflix.Notify.failure(t('notify.pleaseFill'));
      return;
    }

    try {
      if (!transactionToEdit) {
        await dispatch(
          addTransaction({
            date,
            description,
            sum,
            operation: operation.value,
            place,
            icon,
            walletId: wallet.value,
          })
        );

        setDescription('');
        setDate(new Date());
        setPlace('');
        setInput('');
        setAnswer('');
        setWallet('');

        onClose();
      } else {
        const dateAsString = date.toISOString();
        const { _id, owner, createdAt, updatedAt, ...editedTransaction } = {
          ...transaction,
          date: dateAsString,
          description,
          sum: Number(answer) || Number(input),
          operation: operation.value,
          place,
          icon,
          walletId: wallet.value,
        };

        await dispatch(
          editTransaction({
            transactionId: transaction._id,
            updatedData: editedTransaction,
          })
        );
        Notiflix.Notify.success(t('notify.addTransactionTwo'));

        onClose();
      }
      await dispatch(getWallets());
    } catch (error) {
      Notiflix.Notify.failure(t('notify.addTransactionTree'));
    }
  };

  const handleClear = () => {
    setDescription('');
    setDate(new Date());
    setSelectedCategory(null);
    setPlace('');
    setInput('');
    setAnswer('');
    setWallet('');
  };

  const handleChangeDescription = e => {
    setDescription(e.target.value);
  };
  const handleChangePlace = e => {
    setPlace(e.target.value);
  };
  const updateWallet = value => {
    setWallet(value);
  };
  const onChangeDate = date => {
    setDate(date);
  };

  useEffect(() => {
    if (walletOptions.length > 0 && transaction.walletId) {
      const selectedWallet = walletOptions.find(
        walletId => walletId._id === transaction.walletId
      );
      setWallet({ label: selectedWallet.name, value: selectedWallet._id });
    }
  }, [walletOptions, transaction.walletId]);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <SwitchOperation operation={operation} setOperation={setOperation} />
        <Category
          icon={icon}
          setIcon={setIcon}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
        />
        <Display
          openCalculator={openCalculator}
          setOpenCalculator={setOpenCalculator}
          input={input}
          setInput={setInput}
          answer={answer}
          setAnswer={setAnswer}
        />
        {openCalculator && (
          <Calculator
            input={input}
            setInput={setInput}
            answer={answer}
            setAnswer={setAnswer}
            value={sum}
            onChange={onChangeTagInput}
            setOpenCalculator={setOpenCalculator}
          />
        )}
        <WalletSelect
          classNamePrefix="wallet-select"
          onChange={updateWallet}
          value={wallet}
          options={walletOptions.map(wallet => ({
            label: wallet.name,
            value: wallet._id,
          }))}
          placeholder={t('placeholder.chooseWallet')}
          disabled={true}
          isSearchable={false}
        />

        <Calendar value={date} changeValue={setDate} onChange={onChangeDate} />
        <div style={{ marginTop: '16px', marginBottom: '16px' }}>
          <Label>
            {t('madalGeneral.description')}
            <Input
              type="text"
              value={description}
              name="description"
              id="description"
              autoComplete="off"
              placeholder="Any remarks"
              onChange={handleChangeDescription}
            />
          </Label>
        </div>
        <div style={{ marginBottom: '24px' }}>
          <Label style={{ position: 'relative' }}>
            {t('modalTransaction.place')}
            <Input
              style={{ paddingLeft: '32px' }}
              type="text"
              value={place}
              name="place"
              id="place"
              autoComplete="off"
              placeholder="Any remarks"
              onChange={handleChangePlace}
            />
            <ImgLocation
              src={locationIcon}
              alt="locationIcon"
              style={{ cursor: 'pointer' }}
            />
          </Label>
        </div>
        <BtnWrap>
          <StyledButton name="orange" type="submit" onClick={handleSubmit}>
            {t('madalGeneral.save')}
          </StyledButton>
          <StyledButton onClick={handleClear} name="white" type="button">
            {t('madalGeneral.clean')}
          </StyledButton>
        </BtnWrap>
      </form>
    </>
  );
};
