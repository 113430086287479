import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { logOut } from 'redux/auth/authOperations';

export const useLogout = handleToggleMenu => {
  const [modalOpen, setModalOpen] = useState(false);

  const dispatch = useDispatch();

  const toggleModal = () => {
    setModalOpen(prevState => !prevState);
    handleToggleMenu();
  };

  const handleClick = async () => {
    await dispatch(logOut());
    toggleModal();
  };

  return { modalOpen, toggleModal, handleClick, setModalOpen };
};
