import { useSelector } from 'react-redux';
import en from '../translate/en/en.json';
import ua from '../translate/ua/ua.json';
import ru from '../translate/ru/ru.json';

import { selectUserLanguage } from 'redux/selectors';

export const useTranslate = () => {
  const lang = useSelector(selectUserLanguage);
  let t;
  switch (lang) {
    case 'en':
      t = en;
      break;
    case 'uk':
      t = ua;
      break;
    case 'ru':
      t = ru;
      break;
    default:
      t = en;
  }
  return t;
};
