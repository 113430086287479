import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const FooterContainer = styled.footer`
  background-color: #fff1e5;
  position: fixed;
  bottom: 0;
  z-index: 1;
  width: 100%;
`;

export const FooterList = styled.ul`
  display: flex;
  width: 100&;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: auto;
  margin-right: auto;
`;

export const FooterItem = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-items: center;
`;

export const FooterItemPlus = styled.li`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  background-color: #ff751d;
  border-radius: 50%;
  left: calc(50% - 23px);
  top: calc(-50% + 10px);
  box-shadow: 0px 4px 10px rgba(153, 70, 0, 0.25);
`;

export const Styledlink = styled(Link)`
  width: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 8px;
`;

export const FooterText = styled.p`
  font-size: 10px;
  line-height: 11px;
  letter-spacing: 0.04em;
  color: #ff751d;
  margin: 0;
  margin-top: 5px;
`;

export const BtnPlus = styled.button`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: none;
  background: #ff7601;
  padding-top: 8px;
  padding-bottom: 8px;
`;