/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
const resetActiveIndex = (setActiveIndex, activeIndex) => {
  if (activeIndex === -1) {
    return;
  } else {
    setActiveIndex(-1);
  }
};
export const useResetActiveIndex = (setActiveIndex, activeIndex) => {
  useEffect(() => {
    resetActiveIndex(setActiveIndex, activeIndex);
  }, []);
};
