import styled from 'styled-components';

const StyledButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 116px;
  height: 44px;
  border-radius: 16px;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.17;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: ${props =>
    props.name === ('white' || 'orange') ? '#FF7601' : '#FFFFFF'};
  background-color: ${props =>
    props.name === ('white' || 'orange') ? '#FFFFFF' : '#FF7601'};

  border: none;
  box-shadow: ${props =>
    props.name === ('white' || 'orange')
      ? '4px 4px 16px rgba(170, 178, 197, 0.25)'
      : '4px 4px 16px rgba(255, 107, 8, 0.25)'};

  &:hover,
  &:focus {
    color: ${p => (p.name === 'orange' ? '#FF7601' : p.theme.colors.white)};
    background-color: ${p =>
      p.name === 'orange' ? '#FFF1E5' : p.theme.colors.accent};
    box-shadow: '4px 4px 16px rgba(255, 107, 8, 0.25)';
  }
`;

export default StyledButton;
