export const theme = {
  colors: {
    main: {
      orangeMain: '#FF7601',
      white: '#FFFFFF',
      orangeLight: '#FFF1E5',
      grayLight: '#EBEBEB',
    },
    text: {
      text: '#52555F',
      gray: '#A6ABB9',
      greenMain: '#01AB3A',
      expenses: '#FF1717',
    },
    diagram: {
      yellow: '#F2FF5C',
      greenLight: '#9DF37F',
      emeraldLight: '#2CCDA6',
      mustard: '#FFA901',
      pink: '#FF068D',
      blue: '#652FFF',
      violet: '#8B44FF',
      red: '#F47979',
      lilac: '#D661FF',
      expenses: '#FF1717',
      greenMain: '#01AB3A',
    },
    gradient: {
      green: '#00AA3A, #ECECEC',
      orange: '#FF7500, #ECECEC',
      chartOrange: '#FF751D, #FFF1E5',
      chartGreen: '#01AB3A, #F4FFF4',
      chartRed: '#FF1717, #FFFAFA',
    },
  },
  input: {
    active: '1px solid #FF7601',
    arror: '1px solid #FF1717',
    succes: '1px solid #01AB3A',
  },
  width: {
    mobile: '360px',
  },
  switch: {
    lightMode: '#FF7601',
    darkMode: '#52555F',
  }
};
