import React from 'react';
import { DisplayS, Input, ButtonCur } from './Calculator.styled';
import Icons from 'images/allSvgFinTrack.svg';

export const Display = ({ input, setInput, answer, setOpenCalculator, setAnswer }) => {
  const onChangeTagInput = e => {
    const re = /^[0-9!%()-+\x2D^glox\xF7\u221A]+$/; 

    if (e.target.value === '' || re.test(e.target.value)) {
      setInput(e.target.value);
      setAnswer(e.target.value);
    }
  };
  const onDisplayClick = () => {
    setOpenCalculator(prevState => !prevState);
  };

  return (
    <>
      <DisplayS>
        <ButtonCur type="button" onClick={onDisplayClick}>
          <svg width="24" height="24" fill={'#FF7601'}>
            <use href={`${Icons}#icon-calculator`}></use>
          </svg>
        </ButtonCur>
        {answer === '' ? (
          <>
            <Input
              type="text"
              name="input"
              style={{ fontsize: '16px' }}
              value={input}
              placeholder="0"
              maxLength={20}
              onChange={onChangeTagInput}
              autoComplete="off"
            />
          </>
        ) : (
          <>
            <Input
              type="text"
              name="value"
              style={{ fontsize: '16px' }}
              value={answer}
              onChange={onChangeTagInput}
            />
          </>
        )}
      </DisplayS>
    </>
  );
};
