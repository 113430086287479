import { createContext, useState } from 'react';

import { createPortal } from 'react-dom';

import {
  // CloseBtn,
  ModalBackdrop,
  ModalContainer,
} from '../ModalFirstEnter/ModalFirstEnter.styled';

import { Transaction } from 'components/Transaction/Transaction';

import { addIconField, sortedTransactionsAll } from 'pages/Planning/Planning';
// import { CloseBtnWpar } from '../ModalReminder/ModalReminder.styled';
// import Icons from 'images/allSvgFinTrack.svg';
const modalRoot = document.querySelector('#modal-root');
export const HasRunContext = createContext(false);

export const HasRunProvider = ({ children }) => {
  const [hasRun, setHasRun] = useState(false);

  return (
    <HasRunContext.Provider value={{ hasRun, setHasRun }}>
      {children}
    </HasRunContext.Provider>
  );
};
export const ModalOverduePayment = ({ onClose, debts }) => {
  const handleBackdropClick = evt => {
    if (evt.target === evt.currentTarget) {
      onClose();
    }
  };

  return createPortal(
    <ModalBackdrop style={{ zIndex: '100' }} onClick={handleBackdropClick}>
      <ModalContainer style={{ padding: 0 }}>
        {/* <CloseBtnWpar>
          <CloseBtn onClick={onClose}>
            <svg width="24" height="24" fill={'#FF7601'}>
              <use href={`${Icons}#icon-close`}></use>
            </svg>
          </CloseBtn>
        </CloseBtnWpar> */}

        <Transaction
          transactions={sortedTransactionsAll(addIconField(debts))}
          allTransactions={false}
          tableHeadingSelect={false}
          deleteType={'debt'}
          overDueType={true}
        />
      </ModalContainer>
    </ModalBackdrop>,
    modalRoot
  );
};
