import {
  AddButton,
  Container,
  RemindersText,
  RemindersTitle,
} from 'components/Reminders/Reminders.styled';
import FooterSvg from 'images/allSvgFinTrack.svg';
import { useState } from 'react';
import { ModalNewDebt } from 'components/Modal/ModalDebt/ModalDebt';
import { useTranslation } from 'react-i18next';

export const Debts = ({ debtsToEdit }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const { t } = useTranslation();

  const toggleModal = () => {
    setModalOpen(prevState => !prevState);
  };
  return (
    <>
      <Container>
        <RemindersTitle>{t('debts.debts')}</RemindersTitle>
        <AddButton onClick={toggleModal}>
          <svg
            width={24}
            height={24}
            fill={'#FF7601'}
            style={{ marginRight: 16 }}
          >
            <use href={`${FooterSvg}#plus`} />
          </svg>
          <RemindersText>{t('debts.addDebts')}</RemindersText>
        </AddButton>
      </Container>
      {modalOpen && (
        <ModalNewDebt debtsToEdit={debtsToEdit} onClose={toggleModal} />
      )}
    </>
  );
};
