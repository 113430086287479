import {
  DescriptionContainer,
  DescriptionInput,
  Label,
} from '../ModalReminder.styled';
import { useTranslation } from 'react-i18next';

export const Description = ({ setDescription, description }) => {
  const { t } = useTranslation();
  
  const handleChangeDescription = e => {
    setDescription(e.target.value);
  };

  return (
    <DescriptionContainer>
      <Label>
        {t('madalGeneral.description')}
        <DescriptionInput
          type="text"
          value={description}
          name="description"
          id="description"
          autoComplete="off"
          placeholder="Any remarks"
          onChange={handleChangeDescription}
        />
      </Label>
    </DescriptionContainer>
  );
};
