import styled from 'styled-components';
import { Link } from 'react-router-dom';
export const Item = styled.li`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 46px;
  background-color: ${({ active, theme }) =>
    active ? theme.colors.main.orangeLight : theme.colors.main.white};
`;
export const List = styled.ul`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-end;
`;
export const Links = styled(Link)`
  display: flex;

  color: var(--text-color, #52555f);
  margin-right: 16px;
  font-size: 12px;
  font-family: Kulim Park;
  line-height: 150%;
  letter-spacing: 0.48px;
`;
