import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import {
  ModalBackdrop,
  CloseBtn,
  CloseBtnWpar,
  CategoriesWrap,
  ModalContainer,
  BtnWrap,
} from '../ModalTransaction.styled';
import {
  CategoryBtn,
  CategoryText,
} from 'components/Modal/ModalTransaction/ModalTransaction.styled';
import Icons from 'images/allSvgFinTrack.svg';
import StyledButton from '../../../Buttons/Buttons.styled';
import { useTranslation } from 'react-i18next';

const modalRoot = document.querySelector('#modal-root');

export const ModalCategories = ({
  onClose,
  setIcon,
  categoriesMore,
  setCategoriesMore,
  setCategories,
  setSelectedCategory,
}) => {
  const [clickedCategoryId, setClickedCategoryId] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    document.body.style.overflow = 'hidden';

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      document.body.style.overflow = 'unset';
    };
  });

  const handleKeyDown = evt => {
    if (evt.code === 'Escape') {
      onClose();
    }
  };

  const handleBackdropClick = evt => {
    if (evt.target === evt.currentTarget) {
      onClose();
    }
  };

  const handleClick = (categoryName, id) => {
    const updatedCategories = [...categoriesMore];
    const clickedCategoryIndex = updatedCategories.findIndex(
      category => category.categoryName === categoryName
    );
    if (clickedCategoryIndex !== -1) {
      const clickedCategory = updatedCategories.splice(
        clickedCategoryIndex,
        1
      )[0];

      updatedCategories.unshift(clickedCategory);
      setIcon(clickedCategory.id);
      setClickedCategoryId(clickedCategory.id);
    } else {
      alert('hi, you have problem'); // нужна кнпка для перезагрузки или переписать код на асинхронный
    }
    setCategoriesMore(updatedCategories);
    setSelectedCategory(id);
  };

  const handleSaveClick = () => {
    if (clickedCategoryId !== null) {
      setIcon(clickedCategoryId);
      setCategories(categoriesMore);
      onClose();
    } else {
      alert('Please select a category before saving.');
    }
  };

  return createPortal(
    <ModalBackdrop onClick={handleBackdropClick}>
      <ModalContainer>
        <CloseBtnWpar>
          <CloseBtn onClick={onClose} type="button">
            <svg width="24" height="24" fill={'#FF7601'}>
              <use href={`${Icons}#icon-arrow-down`}></use>
            </svg>
          </CloseBtn>
        </CloseBtnWpar>
        <CategoriesWrap>
          {categoriesMore.map(({ id, categoryName }) => (
            <li key={id} style={{ listStyle: 'none' }}>
              <CategoryBtn onClick={() => handleClick(categoryName, id)}>
                <svg
                  width="24"
                  height="24"
                  fill={'#FF7601'}
                  style={{
                    marginBottom: '2px',
                    paddingLeft: '15px',
                    paddingRight: '15px',
                  }}
                >
                  <use href={`${Icons}#${id}`}></use>
                </svg>
                <CategoryText text={categoryName} length={9} />
              </CategoryBtn>
            </li>
          ))}
        </CategoriesWrap>
        <BtnWrap>
          <StyledButton onClick={handleSaveClick} name="orange" type="submit">
            {t('madalGeneral.save')}
          </StyledButton>
          <StyledButton onClick={onClose} name="white" type="white">
            {t('madalGeneral.back')}
          </StyledButton>
        </BtnWrap>
      </ModalContainer>
    </ModalBackdrop>,
    modalRoot
  );
};
