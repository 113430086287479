import React from 'react';
import { Item, Links, List } from './BurgerMenuList.styled';
import { useTranslation } from 'react-i18next';

export const BurgerMenuList = ({
  onClose,
  activeIndex,
  setActiveIndex,
  toggleModal,
}) => {
  const { t } = useTranslation();
  const handleMenuClick = index => {
    setActiveIndex(index);
    onClose();
  };

  return (
    <List>
      <Item active={activeIndex === 0}>
        <Links to="/personal-data" onClick={() => handleMenuClick(0)}>
          {t('carousel.personalData')}
        </Links>
      </Item>
      <Item active={activeIndex === 1}>
        <Links to="/notifications" onClick={() => handleMenuClick(1)}>
          {t('carousel.notifications')}
        </Links>
      </Item>
      <Item active={activeIndex === 2}>
        <Links to="/security" onClick={() => handleMenuClick(2)}>
          {t('carousel.security')}
        </Links>
      </Item>
      <Item active={activeIndex === 3}>
        <Links to="/contact-support" onClick={() => handleMenuClick(3)}>
          {t('carousel.contactSupport')}
        </Links>
      </Item>
      <Item active={activeIndex === 4}>
        <Links to="/donate" onClick={() => handleMenuClick(4)}>
          {t('carousel.donate')}
        </Links>
      </Item>
      <Item active={activeIndex === 5}>
        <Links onClick={toggleModal}>{t('carousel.exit')}</Links>
      </Item>
    </List>
  );
};
