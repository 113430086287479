//

import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { Notify } from 'notiflix/build/notiflix-notify-aio';
import { getPeriod } from 'utils/getPeriod';

export const newDebt = createAsyncThunk(
  'debts/newDebt',
  async (
    { debt, name, description, walletId, amount, date, icon, paymentsByMonth },
    ThunkAPI
  ) => {
    try {
      const { data } = await axios.post('/debts', {
        debt,
        name,
        description,
        walletId,
        amount,
        date,
        icon,
        paymentsByMonth,
      });
      return data;
    } catch (e) {
      Notify.warning(e.response.data.message);
      return ThunkAPI.rejectWithValue(e.message);
    }
  }
);

export const getDebt = createAsyncThunk(
  'debts/getDebt',
  async ({ dateRange }, ThunkAPI) => {
    try {
      const [start, end] = getPeriod(dateRange);
      const { data } = await axios.get(`/debts`, {
        params: {
          start,
          end,
        },
      });
      return data;
    } catch (e) {
      Notify.warning(e.response.data.message);
      return ThunkAPI.rejectWithValue(e.message);
    }
  }
);

export const deleteDebt = createAsyncThunk(
  'debts/deleteDebt',
  async ({ debtsId }, ThunkAPI) => {
    try {
      const { data } = await axios.delete(`/debts/${debtsId}`);

      return { data, debtsId };
    } catch (e) {
      Notify.warning(e.response.data.message);
      return ThunkAPI.rejectWithValue(e.message);
    }
  }
);

export const editDebts = createAsyncThunk(
  'debts/editDebts',
  async ({ debtId, updatedData }, ThunkAPI) => {
    try {
      const { data } = await axios.put(`/debts/${debtId}`, updatedData);

      return data;
    } catch (e) {
      Notify.warning(e.response.data.message);
      return ThunkAPI.rejectWithValue(e.message);
    }
  }
);
