import { PaymentLines } from 'components/Modal/ModalDebt/DebtSetup';
import { theme } from 'utils/constants';

export const PayDate = ({
  payDate,
  datePayments,
  paymentsByMonth,
  paidStatusMarker,
  payments,
}) => {
  const paid = paymentsByMonth.map(el => el.paid);

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  let isOverdue = false;
  let date;

  if (datePayments && datePayments.length > 0) {
    const sortedDates = [...datePayments].sort(
      (a, b) => new Date(a) - new Date(b)
    );
    const nearestDate = new Date(sortedDates[0]);
    nearestDate.setHours(0, 0, 0, 0);

    isOverdue = nearestDate < today;

    date = nearestDate.toLocaleDateString('ru-RU', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
  } else {
    const startDate = new Date(payDate);
    const nextMonthDate = new Date(
      startDate.setMonth(startDate.getMonth() + 1)
    );

    date = nextMonthDate.toLocaleDateString('ru-RU', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
  }

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'start' }}>
        <p
          style={{
            color: isOverdue ? 'red' : '#FF7601',

            fontSize: 10,
            fontWeight: 400,
            lineHeight: '150%',
            letterSpacing: '4%',
            textAlign: 'left',
            marginLeft: 0,
            marginRight: 8,
          }}
        >
          Before
        </p>

        <p
          style={{
            fontSize: 10,
            color: theme.colors.text.text,
            fontWeight: 400,
            lineHeight: '150%',
            letterSpacing: '4%',
            textAlign: 'left',
          }}
        >
          {date}
        </p>
      </div>
      {!paidStatusMarker && (
        <PaymentLines
          paymentsByMonth={paymentsByMonth}
          payments={payments}
          datePayments={datePayments}
          paid={paid}
          paidStatusMarker={paidStatusMarker}
        />
      )}
    </>
  );
};
