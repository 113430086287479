import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getTransactions } from 'redux/transactions/transactionsOperations';

export const useGetTransactions = (operation, dateRange) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getTransactions({ operation: operation.toLowerCase(), dateRange })
    );
  }, [dispatch, operation, dateRange]);
};
