import styled from 'styled-components';

export const AuthTitle = styled.p`
  display: flex;
  font-family: 'Kulim Park';
  font-size: 12px;
  line-height: 1.5;
  letter-spacing: 0.48px;
  color: ${p => p.theme.colors.text.text};
  margin-bottom: 24px;
`;

export const SocialBtnWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SocialBtn = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${p => p.theme.colors.main.white};
  width: calc(100% - 120px);
  height: 40px;
  text-decoration: none;
  border-radius: 30px;
  box-shadow: 4px 4px 16px rgba(170, 178, 197, 0.25);
`;

export const Text = styled.p`
  font-family: Kulim Park;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.48px;
  margin: 0;
  color: #52555f;
`;
