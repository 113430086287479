import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { Notify } from 'notiflix/build/notiflix-notify-aio';
import { store } from 'redux/store';

const setAuthHeader = token => {
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
};

const clearAuthHeader = () => {
  axios.defaults.headers.common.Authorization = '';
};

axios.interceptors.response.use(
  response => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (error.response.status === 403 && !originalRequest._retry) {
      originalRequest._retry = true;

      const token = store.getState().auth.token;
      const newToken = await refreshToken(token);

      setAuthHeader(newToken.accessToken);
      originalRequest.headers.Authorization = `Bearer ${newToken.accessToken}`;

      store.dispatch(
        tokenToState({
          token: newToken.refreshToken,
          expiresIn: newToken.expiresIn,
        })
      );

      return axios(originalRequest);
    }
    if (error.response.status === 401 || error.response.status === 403) {
      store.dispatch(logOutWithError());
    }
    return Promise.reject(error);
  }
);

export const tokenToState = createAsyncThunk(
  'auth/token',
  async (credentials, ThunkAPI) => {
    return credentials;
  }
);

export const register = createAsyncThunk(
  'auth/register',
  async (credentials, ThunkAPI) => {
    try {
      const { data } = await axios.post('/auth/register', credentials);
      setAuthHeader(data.token.accessToken);
      data.token = {
        token: data.token.refreshToken,
        expiresIn: data.token.expiresIn,
      };

      return data;
    } catch (e) {
      Notify.warning(e.response.data.message);
      return ThunkAPI.rejectWithValue(e.message);
    }
  }
);

export const firstEnter = createAsyncThunk(
  'auth/firstEnter',
  async (credentials, ThunkAPI) => {
    try {
      const { data } = await axios.patch('/auth/first-enter', credentials);
      return data;
    } catch (e) {
      Notify.warning(e.response.data.message);
      return ThunkAPI.rejectWithValue(e.message);
    }
  }
);

export const logIn = createAsyncThunk(
  'auth/login',
  async (credentials, ThunkAPI) => {
    try {
      const { data } = await axios.post('/auth/login', credentials);
      setAuthHeader(data.token.accessToken);
      data.token = {
        token: data.token.refreshToken,
        expiresIn: data.token.expiresIn,
      };

      return data;
    } catch (e) {
      Notify.warning(e.response.data.message);
      return ThunkAPI.rejectWithValue(e.message);
    }
  }
);

export const logOut = createAsyncThunk('auth/logout', async (_, thunkAPI) => {
  try {
    localStorage.setItem('path', '/auth');
    await axios.post('/auth/logout');
    clearAuthHeader();
  } catch (error) {
    Notify.warning(error.response.data.message);
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const logOutWithError = createAsyncThunk(
  'auth/logOutWithError',
  async (_, thunkAPI) => {
    try {
      clearAuthHeader();
    } catch (error) {
      Notify.warning(error.response.data.message);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const RefreshUser = createAsyncThunk(
  'auth/refresh',
  async (_, thunkAPI) => {
    const state = thunkAPI.getState();
    const persistedToken = state.auth.token;

    if (persistedToken === null) {
      return thunkAPI.rejectWithValue('Unable to fetch user');
    }
    try {
      const token = await refreshToken(persistedToken);
      setAuthHeader(token.accessToken);
      const { data } = await axios.get('/users/current');
      data.token = {
        token: token.refreshToken,
        expiresIn: token.expiresIn,
      };
      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const googleUser = createAsyncThunk(
  'auth/google',
  async (token, thunkAPI) => {
    try {
      setAuthHeader(token.accessToken);
      const { data } = await axios.get('/users/current');
      data.token = {
        token: token.refreshToken,
        expiresIn: token.expiresIn,
      };
      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const refreshToken = async ({ token }) => {
  const refreshToken = { refreshToken: token };
  const { data } = await axios.post('/auth/refresh', refreshToken);
  return data;
};

export const resetPassword = createAsyncThunk(
  '/auth/password/send-code',
  async (email, { rejectWithValue }) => {
    try {
      const { data } = await axios.post('/auth/password/send-code', { email });
      Notify.success('Password recovery email sent');
      return data;
    } catch (error) {
      Notify.error('Failed to reset password');
      return rejectWithValue(error.message);
    }
  }
);

export const passwordRecovery = createAsyncThunk(
  '/auth/password/recovery',
  async ({ password, code, email }, { rejectWithValue }) => {
    try {
      const { data } = await axios.patch('/auth/password/recovery', {
        password,
        code,
        email,
      });
      Notify.success('Your password recovered');
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const deleteUser = createAsyncThunk(
  '/auth/delete-account',
  async (userEmail, thunkAPI) => {
    try {
      await axios.delete('/auth/delete-account', {
        data: { email: userEmail },
      });
      await logOut();
    } catch (error) {
      Notify.warning(error.response.data.message);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const changeSettings = createAsyncThunk(
  'auth/changeSettings',
  async (credentials, ThunkAPI) => {
    try {
      const { data } = await axios.patch('/users/changeSettings', credentials);
      return data;
    } catch (error) {
      Notify.warning(error.response.data.message);
      return ThunkAPI.rejectWithValue(error.message);
    }
  }
);
