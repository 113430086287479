import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { createPortal } from 'react-dom';
import { newDebt } from 'redux/debt/debtOperations';
import { SwitchDebt, TextDebt, WrapIM, WrapI } from './ModalDebt.styled';
import {
  CloseBtn,
  ModalBackdrop,
  ModalContainer,
  CloseBtnWpar,
  WalletSelect,
  BtnWrap,
} from '../ModalTransaction/ModalTransaction.styled';
import { Text } from '../ModalReminder/ModalReminder.styled';
import Icons from 'images/allSvgFinTrack.svg';

import { Calendar } from 'components/Calendar/Calendar';
import StyledButton from 'components/Buttons/Buttons.styled';
import verticalline from 'images/line-vertical.svg';
import { selectAllWallet, selectTokenDeadline } from 'redux/selectors';
import { useTranslation } from 'react-i18next';
import {
  calculatePayments,
  handleClear,
  handleEditDebt,
  updateDebt,
} from './DebtSetup';

import Notiflix, { Notify } from 'notiflix';
import { SliderPayments } from './SliderPaymets';
import { AmountComponent } from './AmountComponent';
import { NameDescriptionComponent } from './NameDescriptionComponent';
import { RefreshUser } from 'redux/auth/authOperations';

const modalRoot = document.querySelector('#modal-root');

export const ModalNewDebt = ({ onClose, debtToEdit, paidType }) => {
  const dispatch = useDispatch();

  const { deadline, walletOptions } = useSelector(state => ({
    deadline: selectTokenDeadline(state),
    walletOptions: selectAllWallet(state),
  }));
  const walletName =
    debtToEdit && walletOptions.find(el => el._id === debtToEdit.walletId);

  const [debt, setDebt] = useState(debtToEdit?.icon || ' ');

  const [description, setDescription] = useState(debtToEdit?.description || '');
  const [name, setName] = useState(debtToEdit?.name || '');
  const [wallet, setWallet] = useState(
    walletName ? { label: walletName.name, value: walletName._id } : ''
  );
  const [date, setDate] = useState(
    debtToEdit?.date ? new Date(debtToEdit.date) : new Date()
  );
  const [amount, setAmount] = useState(debtToEdit?.sum || '');
  const [count, setCount] = useState(debtToEdit?.paymentsByMonth.length || 1);

  const [activeButtonImOwed, setActiveButtonImOwed] = useState(
    debt === 'icon-Im-owed' ? true : false
  );
  const [activeButtonIOwe, setActiveButtonIOwe] = useState(
    debt === 'icon-I-owe' ? true : false
  );

  const [payments, setPayments] = useState([]);
  const { t } = useTranslation();
  console.log(payments);
  // const handlePaymentChange = (index, newAmount) => {
  //   const updatedPayments = [...payments];
  //   updatedPayments[index].amount = newAmount;

  //   let remainingAmount = amount - newAmount;
  //   for (let i = index + 1; i < updatedPayments.length; i++) {
  //     const monthlyPayment = Math.round(remainingAmount / (count - i));
  //     updatedPayments[i].amount = monthlyPayment;
  //     remainingAmount -= monthlyPayment;
  //   }

  //   setPayments(updatedPayments);
  // };
  // const handlePaymentDateChange = (index, newDate) => {
  //   const updatedPayments = [...payments];
  //   updatedPayments[index].date = newDate;
  //   setPayments(updatedPayments);
  // };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    document.body.style.overflow = 'hidden';

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      document.body.style.overflow = 'unset';
    };
  });

  const handleKeyDown = evt => {
    if (evt.code === 'Escape') {
      onClose();
    }
  };

  const handleBackdropClick = evt => {
    if (evt.target === evt.currentTarget) {
      onClose();
    }
  };

  const handleChangeDescription = e => {
    setDescription(e.target.value);
  };
  const handleChangeName = e => {
    setName(e.target.value);
  };
  const updateWallet = value => {
    setWallet(value);
  };
  const onChangeDate = date => {
    setDate(date);
  };
  const handleAmountChange = e => {
    setAmount(e.currentTarget.value);
  };

  const validateFields = () => {
    return debt && date && name && description && wallet.value && amount;
  };

  const createDebtObject = () => ({
    debt,
    date,
    name,
    description,
    walletId: wallet.value,
    amount,
    paymentsByMonth: debtToEdit
      ? updateDebt(debtToEdit, true, count).paymentsByMonth
      : calculatePayments(date, count, amount),
    icon: `icon-${debt === 'Im owed' ? 'Im-owed' : 'I-owe'}`,
  });

  const handleNewDebt = newDebtObj => {
    console.log('newDebtObj', newDebtObj);
    dispatch(newDebt(newDebtObj));
    Notiflix.Notify.success(t('notify.addDebtOne'));
  };

  // const handleEditDebt = newDebtObj => {
  //   dispatch(
  //     editDebts({
  //       debtId: debtToEdit._id,
  //       updatedData: newDebtObj,
  //     })
  //   );
  //   Notiflix.Notify.success(t('notify.addWalletTwo'));
  // };
  const save = () => {
    if (deadline && Date.now() >= deadline) {
      dispatch(RefreshUser());
    }

    try {
      if (!validateFields()) {
        Notify.failure(t('notify.validateFields'));
        return;
      }

      const newDebtObj = createDebtObject();

      if (!debtToEdit) {
        handleNewDebt(newDebtObj);
      } else {
        handleEditDebt(newDebtObj);
      }

      onClose();
    } catch (error) {
      Notify.failure('Error saving debt:', error);
    }
  };
  const handleSelectDebt = (selectedDebt, e) => {
    const isImOwed = e.currentTarget.id === 'Im-owed';
    setActiveButtonImOwed(isImOwed);
    setActiveButtonIOwe(!isImOwed);

    setDebt(selectedDebt);
  };

  return createPortal(
    <ModalBackdrop onClick={handleBackdropClick}>
      <ModalContainer style={{ overflowY: 'scroll' }}>
        <CloseBtnWpar>
          <CloseBtn onClick={onClose} type="button">
            <svg width="24" height="24" fill={'#FF7601'}>
              <use href={`${Icons}#icon-arrow-down`}></use>
            </svg>
          </CloseBtn>
        </CloseBtnWpar>
        <SwitchDebt>
          <WrapIM
            active={activeButtonImOwed}
            id="Im-owed"
            onClick={e => handleSelectDebt('Im owed', e)}
          >
            <svg width="24" height="24">
              <use href={`${Icons}#icon-Im-owed`}></use>
            </svg>
            <TextDebt>{t('modalDebt.imOwed')}</TextDebt>
          </WrapIM>
          <img src={verticalline} alt={verticalline} />
          <WrapI
            active={activeButtonIOwe}
            id="I-owe"
            onClick={e => handleSelectDebt('I owe', e)}
          >
            <svg width="24" height="24">
              <use href={`${Icons}#icon-I-owe`}></use>
            </svg>
            <TextDebt>{t('modalDebt.iOwe')}</TextDebt>
          </WrapI>
        </SwitchDebt>
        <NameDescriptionComponent
          style={{ marginBottom: '16px' }}
          value={name}
          name={'name'}
          id={'name'}
          placeholder={'Text'}
          onChange={handleChangeName}
        />

        <NameDescriptionComponent
          style={{ marginBottom: '16px' }}
          value={description}
          name={'description'}
          id={'description'}
          placeholder={'Any remarks'}
          onChange={handleChangeDescription}
        />
        <WalletSelect
          classNamePrefix="wallet-select"
          onChange={updateWallet}
          value={wallet}
          options={walletOptions.map(wallet => ({
            label: wallet.name,
            value: wallet._id,
          }))}
          placeholder={t('placeholder.chooseWallet')}
          disabled={true}
          isSearchable={false}
        />

        <AmountComponent
          handleAmountChange={handleAmountChange}
          amount={amount}
        />
        <div style={{ marginBottom: '16px' }}>
          <Text>
            {t('modalDebt.dateOf')}
            <span style={{ color: '#FF7601' }}>{t('modalDebt.finalPaym')}</span>
          </Text>
          <Calendar
            value={date}
            changeValue={setDate}
            onChange={onChangeDate}
          />
        </div>

        <SliderPayments amount={amount} count={count} setCount={setCount} />

        <BtnWrap>
          <StyledButton name="orange" type="submit" onClick={save}>
            {t('madalGeneral.save')}
          </StyledButton>

          <StyledButton
            onClick={() => {
              handleClear(
                setDescription,
                setDate,
                setName,
                setWallet,
                setAmount,
                setCount,
                setPayments
              );
            }}
            name="white"
            type="white"
          >
            {t('madalGeneral.clean')}
          </StyledButton>
        </BtnWrap>
      </ModalContainer>
    </ModalBackdrop>,
    modalRoot
  );
};
