import styled from 'styled-components';

export const TransactionContainer = styled.div`
  display: block;
  margin: auto;
  margin-bottom: ${props => (props.overDueType ? '0px' : '24px')};
  background-color: ${p => p.theme.colors.white};
  margin-top: ${props =>
    props.isWalletView ? '4px' : props.overDueType ? '0px' : '40px'};
  box-shadow: 2px 8px 80px rgba(170, 178, 197, 0.25);
  border-top-left-radius: ${props => (props.isWalletView ? '0px' : '30px')};
  border-top-right-radius: ${props => (props.isWalletView ? '0px' : '30px')};
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
`;

export const TransactionWrapper = styled.div`
  display: block;
  padding: 16px 20px 24px 24px;
  background-color: ${p => p.theme.colors.white};
`;

export const WrapSearch = styled.div`
  display: block;
  padding: 16px 24px 8px 24px;
`;

export const Line = styled.div`
  display: block;
  width: 100%;
  border: 1px solid #fff1e5;
`;

export const Scrollbar = styled.div`
  overflow-y: scroll;

  max-height: 360px;
  ::-webkit-scrollbar {
    width: 2px;
  }
  ::-webkit-scrollbar-track-piece {
    background: #fff1e5;
  }
  ::-webkit-scrollbar-thumb {
    background: #ff7601;
  }
  ::-webkit-resizer {
  }
`;
