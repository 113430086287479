//Auth
export const selectIsLoggedIn = state => state.auth.isLoggedIn;
export const selectIsRefreshing = state => state.auth.isRefreshing;
export const selectTokenDeadline = state => state.auth.token?.expiresIn;
export const selectEmail = state => state.auth.user.email;
export const selectUserNickname = state => state.auth.settings.nickname;
export const selectUserLanguage = state => state.auth.settings.language;
export const selectUserSettings = state => state.auth.settings;
export const selectUserCurrency = state => state.auth.settings.currency;

// Transactions
export const selectIsLoading = state => state.transactions.isLoading;
export const selectTransactions = state => state.transactions.items;
export const selectTransactionsLast = state => state.transactions.lastItems;
export const selectOperationType = state => state.transactions.operationType;
// Page State
export const selectIsAll = state => state.statePage.isAll;
export const selectIsIncome = state => state.statePage.isIncome;
//Wallet State
export const selectAllWallet = state => state.wallet.entities;
// Reminders
export const selectReminders = state => state.reminders.items;
// Debt
export const selectDebt = state => state.debt.items;
