import styled from 'styled-components';

export const LoaderWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  background-color: rgb(255, 255, 255);

  box-shadow: 2px 8px 80px rgba(170, 178, 197, 0.25);
  border-radius: 30px;

  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -50px;
  margin-top: -50px;
`;
