import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { createPortal } from 'react-dom';
import { resetPassword } from 'redux/auth/authOperations';
import { useNavigate } from 'react-router-dom';

import {
  FormText,
  Input,
  Label,
  Message,
  SmallIconWrap,
} from 'components/Modal/ModalPassRecovery/ModalPassRecovery.styled';

import Icons from 'images/allSvgFinTrack.svg';
import {
  BtnsContainer,
  CloseBtn,
  ConfirmBtn,
  ModalBackdrop,
  ModalContainer,
} from '../ModalFirstEnter/ModalFirstEnter.styled';
import { useTranslation } from 'react-i18next';

const modalRoot = document.querySelector('#modal-root');

export const ModalPassReset = ({
  onClose,
  emailForResend,
  setEmailForResend,
}) => {
  const [emailStatus, setEmailStatus] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const handleBackdropClick = evt => {
    if (evt.target === evt.currentTarget) {
      onClose();
    }
  };

  const handleResendPassord = e => {
    setEmailForResend(e.target.value);
    setEmailStatus(null);
  };

  const onConfirm = () => {
    dispatch(resetPassword(emailForResend))
      .then(response => {
        if (response.error) {
          setEmailStatus('error');
        } else {
          setEmailStatus('success');
          navigate('/auth/modal-password-recovery');
        }
      })
      .catch(() => {
        setEmailStatus('error');
      });
  };

  let inputStyles = {};
  let message = '';
  let messageStyles = {};
  let icon = null;

  if (emailStatus === 'success') {
    inputStyles = { border: '1px solid #01AB3A', marginBottom: '8px' };
    message = 'Mission "Email Confirmation" has been successfully completed!';
    messageStyles = { color: '#01AB3A' };
    icon = (
      <svg width="12" height="12">
        <use href={`${Icons}#icon-green-check`}></use>
      </svg>
    );
  } else if (emailStatus === 'error') {
    inputStyles = { border: '1px solid #FF1717', marginBottom: '8px' };
    message =
      'Mission "Email Confirmation" has been used up. Please try again.';
    messageStyles = { color: '#FF1717' };
    icon = (
      <svg width="12" height="12">
        <use href={`${Icons}#icon-red-cross`}></use>
      </svg>
    );
  }

  return createPortal(
    <ModalBackdrop onClick={handleBackdropClick}>
      <ModalContainer>
        <CloseBtn onClick={onClose} type="button">
          <svg width="24" height="24" fill={'#FF7601'}>
            <use href={`${Icons}#icon-close`}></use>
          </svg>
        </CloseBtn>
        <FormText style={{ marginBottom: '24px' }}>
          {t('modalPassReset.formTextOne')} <br />
          {t('modalPassReset.formTextTwo')}
        </FormText>
        <Label>{t('carousel.email')}:</Label>
        <Input
          type="email"
          name="email"
          value={emailForResend}
          placeholder="12345Qwerty"
          autoComplete="off"
          onChange={handleResendPassord}
          style={{ marginBottom: '32px', ...inputStyles }}
        />
        <SmallIconWrap>{icon}</SmallIconWrap>
        <Message style={{ ...messageStyles }}>{message}</Message>
        <BtnsContainer>
          <ConfirmBtn onClick={onConfirm} type="button">
            {t('madalGeneral.confirm')}
          </ConfirmBtn>
        </BtnsContainer>
      </ModalContainer>
    </ModalBackdrop>,
    modalRoot
  );
};
