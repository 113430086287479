import { createSlice } from '@reduxjs/toolkit';
import {
  addTransaction,
  deleteTransaction,
  getReport,
  getTransactions,
  getTransactionsLast,
  editTransaction,
} from './transactionsOperations';

const initialState = {
  isLoading: false,
  items: [],
  lastItems: [],
  report: {},
  error: null,
  operationType: 'all',
};

const handlePending = state => {
  state.isLoading = true;
};

const handleError = (state, action) => {
  state.isLoading = false;
  state.error = action.payload || 'An error occurred';
};
const handleFulfilled = state => {
  state.isLoading = false;
  state.error = null;
};

const transactionsSlice = createSlice({
  name: 'transaction',
  initialState,
  extraReducers: builder =>
    builder
      .addCase(addTransaction.pending, handlePending)
      .addCase(addTransaction.fulfilled, (state, action) => {
        handleFulfilled(state);
        if (Array.isArray(state.items)) {
          state.items.push(action.payload.result);
        } else {
          state.items = [action.payload.result];
        }
      })
      .addCase(addTransaction.rejected, handleError)
      .addCase(deleteTransaction.pending, handlePending)
      .addCase(deleteTransaction.fulfilled, (state, action) => {
        handleFulfilled(state);
        state.items = state.items.filter(
          item => item._id !== action.payload.id
        );
      })
      .addCase(deleteTransaction.rejected, handleError)
      .addCase(getTransactions.pending, state => {
        state.isLoading = true;
      })
      .addCase(getTransactions.fulfilled, (state, { payload }) => {
        state.items = payload;
        state.error = null;
        state.isLoading = false;
      })
      .addCase(getTransactions.rejected, (state, action) => {
        state.error = action.error.message;
        state.isLoading = false;
      })

      .addCase(getTransactionsLast.pending, state => {
        state.isLoading = true;
      })
      .addCase(getTransactionsLast.fulfilled, (state, { payload }) => {
        state.lastItems = payload;
        state.error = null;
        state.isLoading = false;
      })
      .addCase(getTransactionsLast.rejected, (state, action) => {
        state.error = action.error.message;
        state.isLoading = false;
      })
      .addCase(getReport.pending, handlePending)
      .addCase(getReport.fulfilled, (state, action) => {
        handleFulfilled(state);
        state.report = action.payload;
      })
      .addCase(getReport.rejected, handleError)
      .addCase(editTransaction.pending, handlePending)
      .addCase(editTransaction.fulfilled, (state, action) => {
        handleFulfilled(state);
        const index = state.items.findIndex(
          item => item._id === action.payload._id
        );
        if (index !== -1) {
          state.items[index] = action.payload;
        }
      })
      .addCase(editTransaction.rejected, handleError),
});

export const transactionsReducer = transactionsSlice.reducer;
