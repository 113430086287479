import { useEffect } from 'react';
import { createPortal } from 'react-dom';
import {
  CloseBtn,
  ModalBackdrop,
  ModalContainer,
  CloseBtnWpar,
} from './ModalTransaction.styled';
import Icons from 'images/allSvgFinTrack.svg';
import { AddTransaction } from 'components/Modal/ModalTransaction/AddTransactionForm/AddTransaction';

const modalRoot = document.querySelector('#modal-root');

export const ModalTransactions = ({ onClose, transactionToEdit }) => {
  const indentation = '254px';

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    document.body.style.overflow = 'hidden';

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      document.body.style.overflow = 'unset';
    };
  });

  const handleKeyDown = evt => {
    if (evt.code === 'Escape') {
      onClose();
    }
  };

  const handleBackdropClick = evt => {
    if (evt.target === evt.currentTarget) {
      onClose();
    }
  };

  return createPortal(
    <ModalBackdrop onClick={handleBackdropClick}>
      <ModalContainer>
        <CloseBtnWpar>
          <CloseBtn onClick={onClose} type="button">
            <svg width="24" height="24" fill={'#FF7601'}>
              <use href={`${Icons}#icon-arrow-down`}></use>
            </svg>
          </CloseBtn>
        </CloseBtnWpar>
        <AddTransaction
          indentation={indentation}
          onClose={onClose}
          transactionToEdit={transactionToEdit}
        />
      </ModalContainer>
    </ModalBackdrop>,
    modalRoot
  );
};
