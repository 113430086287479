import styled from 'styled-components';

export const Button = styled.button`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #fff1e5;
  border: 1px solid #ff7601;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ButtonsWrap = styled.div`
  gap: 8px;
  display: flex;
  justify-content: center;
`;
