import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-calendar/dist/Calendar.css';
import {
  CalendarWrap,
  SmallButton,
  SmallCalWrap,
  CalOrangeBtn,
  CalOrangeWrap,
  CalClockWrap,
  Buttons,
} from 'components/Calendar/Calendar.styled';
import Icons from 'images/allSvgFinTrack.svg';
import { getDate } from 'redux/calendar/calendarSlice';
import { useTranslation } from 'react-i18next';

export const Calendar = ({ value, changeValue }) => {
  const [isOpen, setIsOpen] = useState(false);
  const openFromIcon = () => {
    setIsOpen(prevState => !prevState);
  };
  const { t } = useTranslation();

  return (
    <CalendarWrap>
      <SmallButton type="button" onClick={openFromIcon}>
        <svg width="16" height="16" fill={'#FF7601'}>
          <use href={`${Icons}#icon-calendar`}></use>
        </svg>
      </SmallButton>

      <SmallCalWrap>
        <DatePicker
          showPopperArrow={false}
          onClickOutside={() => setIsOpen(false)}
          open={isOpen}
          selected={value}
          shouldCloseOnSelect={false}
          dateFormat="dd.MM.yyyy"
          readOnly
          onChange={date => changeValue(date)}
        >
          <div>
            <Buttons
              onClick={() => {
                setIsOpen(false);
              }}
            >
              {t('calendar.btnCansel')}
            </Buttons>
            <Buttons
              onClick={() => {
                setIsOpen(false);
              }}
            >
              {t('calendar.btnOk')}
            </Buttons>
          </div>
        </DatePicker>
      </SmallCalWrap>
    </CalendarWrap>
  );
};

export const CalendarOrange = ({ value, changeValue }) => {
  const dispatch = useDispatch();
  const prevValueRef = useRef();

  useEffect(() => {
    const prevValue = prevValueRef.current;
    const year = String(value.getFullYear());
    const month = String(value.getMonth() + 1).padStart(2, '0');
    const day = String(value.getDate()).padStart(2, '0');

    const date = {
      year,
      month,
      day,
    };

    if (!prevValue || JSON.stringify(date) !== JSON.stringify(prevValue)) {
      dispatch(getDate(date));
    }

    prevValueRef.current = date;
  }, [dispatch, value]);

  const [isOpen, setIsOpen] = useState(false);
  const openFromIcon = () => {
    setIsOpen(prevState => !prevState);
  };

  return (
    <CalendarWrap>
      <CalOrangeBtn type="button" onClick={openFromIcon}>
        <svg width="16" height="16" fill={'#FF7601'}>
          <use href={`${Icons}#icon-calendar`}></use>
        </svg>
      </CalOrangeBtn>

      <CalOrangeWrap onClick={openFromIcon}>
        <DatePicker
          showPopperArrow={false}
          onClickOutside={() => setIsOpen(false)}
          open={isOpen}
          selected={value}
          shouldCloseOnSelect={false}
          dateFormat="dd.MM.yyyy"
          readOnly
          onChange={date => changeValue(date)}
        ></DatePicker>
      </CalOrangeWrap>
    </CalendarWrap>
  );
};

export const CalendarWithClock = ({ value, changeValue }) => {
  const [isOpen, setIsOpen] = useState(false);
  const openFromIcon = () => {
    setIsOpen(prevState => !prevState);
  };
  const { t } = useTranslation();

  return (
    <CalendarWrap>
      <SmallButton type="button" onClick={openFromIcon}>
        <svg width="16" height="16" fill={'#FF7601'}>
          <use href={`${Icons}#icon-calendar`}></use>
        </svg>
      </SmallButton>

      <CalClockWrap>
        <DatePicker
          showPopperArrow={false}
          onClickOutside={() => setIsOpen(false)}
          open={isOpen}
          selected={value}
          shouldCloseOnSelect={false}
          dateFormat="dd.MM.yyyy h:mm aa"
          readOnly
          onChange={date => changeValue(date)}
          showTimeInput
          timeInputLabel="Time:"
        >
          <div>
            <Buttons
              onClick={() => {
                setIsOpen(false);
              }}
            >
              {t('calendar.btnCansel')}
            </Buttons>
            <Buttons
              onClick={() => {
                setIsOpen(false);
              }}
            >
              {t('calendar.btnOk')}
            </Buttons>
          </div>
        </DatePicker>
      </CalClockWrap>
    </CalendarWrap>
  );
};
