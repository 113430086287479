import { createSlice } from '@reduxjs/toolkit';

const searchInitialState = '';

const searchSlice = createSlice({
  name: 'search',
  initialState: searchInitialState,
  reducers: {
    setStatusSearch(state, action) {
      return (state = action.payload);
    },
  },
});

export const { setStatusSearch } = searchSlice.actions;
export const searchReducer = searchSlice.reducer;
