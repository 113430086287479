import { Notify } from 'notiflix';

import { editDebts, newDebt } from 'redux/debt/debtOperations';
import { theme } from 'utils/constants';
import { SectorLine } from './ModalDebt.styled';

export const calculatePayments = (startDate, numberOfMonths, amount) => {
  if (amount < 0) {
    console.log('amount < 0');
  }
  const payments = [];

  const start = new Date(startDate);
  const end = new Date(start);
  end.setMonth(start.getMonth() + numberOfMonths);

  const monthlyPayment = Math.round(amount / numberOfMonths);
  let currentDate = new Date(start);

  for (let i = 0; i < numberOfMonths; i++) {
    if (i === 0) {
      const firstPaymentDate = new Date(currentDate);
      firstPaymentDate.setMonth(currentDate.getMonth() + 1);
      payments.push({
        paid: null,
        date: firstPaymentDate,
        amount: monthlyPayment,
      });
    } else {
      const nextPaymentDate = new Date(currentDate);
      nextPaymentDate.setMonth(currentDate.getMonth() + i + 1);
      payments.push({
        paid: null,
        date: nextPaymentDate,
        amount: monthlyPayment,
      });
    }
  }

  return payments;
};
export const handlePaymentChange = (
  index,
  newAmount,
  setPayments,
  payments,
  amount,
  count
) => {
  const updatedPayments = [...payments];
  updatedPayments[index].amount = newAmount;

  let remainingAmount = amount - newAmount;
  for (let i = index + 1; i < updatedPayments.length; i++) {
    const monthlyPayment = Math.round(remainingAmount / (count - i));
    updatedPayments[i].amount = monthlyPayment;
    remainingAmount -= monthlyPayment;
  }

  setPayments(updatedPayments);
};

export const handleClear = (
  setDescription,
  setDate,
  setName,
  setWallet,
  setAmount,
  setCount,
  setChecked,
  setCheckedPayments,
  setPayments
) => {
  setDescription('');
  setDate(new Date());
  setName('');
  setWallet('');
  setAmount('');
  setCount('');
  setChecked(false);
  setCheckedPayments(false);
  setPayments([]);
};
export const filterOverdue = objects => {
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);

  const filteredObjects = objects.filter(object => {
    const objectDate = new Date(object.date);
    objectDate.setHours(0, 0, 0, 0);

    let closestPaymentDate = null;
    if (object.paymentsByMonth.length > 0) {
      closestPaymentDate = object.paymentsByMonth.reduce(
        (closestDate, payment) => {
          const paymentDate = new Date(payment.date);
          return closestDate === null ||
            Math.abs(paymentDate - currentDate) <
              Math.abs(new Date(closestDate) - currentDate)
            ? payment.date
            : closestDate;
        },
        null
      );

      closestPaymentDate = new Date(closestPaymentDate);
      closestPaymentDate.setHours(0, 0, 0, 0);
    }

    return (
      objectDate <= currentDate ||
      (closestPaymentDate !== null && closestPaymentDate <= currentDate)
    );
  });

  return filteredObjects;
};

export const validateFields = (
  debt,
  date,
  name,
  description,
  wallet,
  amount
) => {
  return debt && date && name && description && wallet.value && amount;
};

export const handleNewDebt = (dispatch, newDebtObj) => {
  dispatch(newDebt(newDebtObj));
  Notify.success('Debt added successfully');
};

export const handleEditDebt = (dispatch, newDebtObj, debtToEdit) => {
  dispatch(
    editDebts({
      debtId: debtToEdit._id,
      updatedData: newDebtObj,
    })
  );
  Notify.success('Debt edited successfully');
};

export const updateDebt = (debtToEdit, paidStatus, newPaymentsCount) => {
  if (!debtToEdit || !debtToEdit.paymentsByMonth) {
    return debtToEdit;
  }

  let date = new Date();
  date.setDate(date.getDate() + 2);

  let paymentsByMonth = [...debtToEdit.paymentsByMonth];

  if (newPaymentsCount && newPaymentsCount !== paymentsByMonth.length) {
    if (newPaymentsCount < paymentsByMonth.length) {
      paymentsByMonth = paymentsByMonth.slice(0, newPaymentsCount);
    } else {
      for (let i = paymentsByMonth.length; i < newPaymentsCount; i++) {
        paymentsByMonth.push({
          paid: null,
          date: new Date(date.setMonth(date.getMonth() + 1)),
          amount: 0,
        });
      }
    }
  }

  paymentsByMonth = paymentsByMonth.map(payment => {
    let paymentDate = new Date(payment.date);
    if (paymentDate <= date) {
      return { ...payment, paid: paidStatus };
    } else {
      return payment;
    }
  });

  let updatedDebt = {
    ...debtToEdit,
    paymentsByMonth,
  };

  return updatedDebt;
};
export const renderPaymentLines = (
  datePayments,
  paidStatusMarker,
  paid,
  flex
) => {
  const minLineWidth = 52;
  const maxLineWidth = 24;
  const paymentCount = paidStatusMarker
    ? ''
    : datePayments.length >= 12
    ? 12
    : datePayments.length < 2
    ? ''
    : datePayments.length;
  const lineWidth = paidStatusMarker
    ? 'auto'
    : `${minLineWidth + (maxLineWidth - minLineWidth) * (paymentCount / 12)}px`;

  const color = (paid, index) => {
    if (!paid || paid.length === 0) {
      return theme.colors.main.orangeLight;
    }
    return paid[index] === null
      ? theme.colors.main.orangeLight
      : paid[index] === false
      ? '#FF1717'
      : theme.colors.diagram.greenMain;
  };

  const paymentLines = Array.from({ length: paymentCount }, (_, index) =>
    paidStatusMarker ? (
      ''
    ) : (
      <SectorLine
        lineWidth={lineWidth}
        color={color}
        paid={paid}
        flex={flex}
        index={index}
      />
    )
  );
  return paymentLines;
};
export const PaymentLines = ({
  paidStatusMarker,
  datePayments,
  paid,
  flex,
}) => {
  return paidStatusMarker ? (
    ''
  ) : (
    <div style={{ display: 'flex' }}>
      {renderPaymentLines(datePayments, paidStatusMarker, paid, flex)}
    </div>
  );
};
