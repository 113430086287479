import styled from 'styled-components';
export const ContainerBurger = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 320px;
  padding: 0 16px 0 16px;
  margin-top: 56px;
  margin-left: auto;
  margin-right: auto;
`;
export const BurgerContainer = styled.div`
  display: flex;
  position: absolute;
  top: 56px;
  right: 0;
  width: 192px;
  justify-content: flex-end;
  padding-bottom: 16px;

  flex-shrink: 0;

  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  background: ${({ theme }) => theme.colors.main.white};

  box-shadow: 4px 4px 16px 0px rgba(170, 178, 197, 0.25);
`;

export const BurgerButton = styled.button`
  width: 24px;
  height: 24px;
  border: none;
  background-color: transparent;

  transition: transform 250ms cubic-bezier(0.17, 0.67, 0.94, 0.61);
`;
export const BurgerMenu = styled.ul``;
