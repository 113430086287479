import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { AuthForm } from 'components/AuthorizationForm/Form/Form';
import { Title, Signature, Line, TitleWrapper } from 'pages/Auth/Auth.styled';
import { googleUser } from 'redux/auth/authOperations';
import { useTranslation } from 'react-i18next';

const AuthPage = ({ emailForResend, setEmailForResend }) => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();

  useEffect(() => {
    const accessToken = searchParams.get('accessToken');
    const refreshToken = searchParams.get('refreshToken');
    const expiresIn = searchParams.get('expiresIn');
    if (accessToken) {
      dispatch(googleUser({ accessToken, refreshToken, expiresIn }));
    }
  }, [dispatch, searchParams]);

  return (
    <TitleWrapper>
      <Title>FinTrack</Title>
      <Line></Line>
      <Signature>{t('authForm.hero')}</Signature>
      <AuthForm
        emailForResend={emailForResend}
        setEmailForResend={setEmailForResend}
      />
    </TitleWrapper>
  );
};
export default AuthPage;
