import { createSlice } from '@reduxjs/toolkit';
import { addBalance } from '../balance/balanceOperation';
import {
  googleUser,
  logIn,
  logOut,
  RefreshUser,
  register,
  resetPassword,
  passwordRecovery,
  deleteUser,
  tokenToState,
  logOutWithError,
  firstEnter,
  changeSettings,
} from './authOperations';

const initialState = {
  user: { email: null },
  settings: { language: navigator.language || navigator.userLanguage },
  token: null,
  isLoggedIn: false,
  isRefreshing: false,
  statusEmail: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  extraReducers: builder =>
    builder
      .addCase(register.fulfilled, (state, action) => {
        state.user = action.payload.user;
        state.settings = action.payload.settings;
        state.token = action.payload.token;
        state.isLoggedIn = true;
      })
      .addCase(logIn.fulfilled, (state, action) => {
        state.user = action.payload.user;
        state.token = action.payload.token;
        state.settings = action.payload.settings;
        state.isLoggedIn = true;
      })
      .addCase(logOut.fulfilled, state => {
        state.user = null;
        state.token = null;
        state.settings = { language: state.settings.language };
        state.isLoggedIn = false;
      })
      .addCase(deleteUser.fulfilled, state => {
        state.user = null;
        state.token = null;
        state.isLoggedIn = false;
      })
      .addCase(RefreshUser.pending, state => {
        state.isRefreshing = true;
        state.isLoggedIn = false;
      })
      .addCase(RefreshUser.fulfilled, (state, action) => {
        state.user = action.payload.user;
        state.settings = action.payload.settings;
        state.token = action.payload.token;
        state.isLoggedIn = true;
        state.isRefreshing = false;
      })
      .addCase(RefreshUser.rejected, state => {
        state.isRefreshing = false;
        state.isLoggedIn = false;
      })
      .addCase(addBalance.pending, state => {
        state.isRefreshing = true;
        state.isLoggedIn = true;
      })
      .addCase(addBalance.fulfilled, (state, action) => {
        state.balance = action.payload.data.balance;
        state.isLoggedIn = true;
        state.isRefreshing = false;
      })
      .addCase(addBalance.rejected, state => {
        state.isRefreshing = false;
        state.isLoggedIn = false;
      })
      .addCase(googleUser.pending, state => {
        state.isRefreshing = false;
        state.isLoggedIn = true;
      })
      .addCase(googleUser.fulfilled, (state, action) => {
        state.user = action.payload.user;
        state.token = action.payload.token;
        state.settings = action.payload.settings;
        state.isLoggedIn = true;
        state.isRefreshing = false;
      })
      .addCase(googleUser.rejected, state => {
        state.isRefreshing = false;
        state.isLoggedIn = false;
      })
      .addCase(resetPassword.pending, state => {
        state.statusEmail = false;
      })
      .addCase(resetPassword.fulfilled, state => {
        state.statusEmail = true;
      })
      .addCase(resetPassword.rejected, state => {
        state.statusEmail = false;
      })
      .addCase(passwordRecovery.pending, state => {
        state.statusEmail = false;
      })
      .addCase(passwordRecovery.fulfilled, state => {
        state.statusEmail = true;
      })
      .addCase(passwordRecovery.rejected, state => {
        state.statusEmail = false;
      })
      .addCase(tokenToState.fulfilled, (state, action) => {
        state.token = action.payload;
        state.isLoggedIn = true;
        state.isRefreshing = false;
      })
      .addCase(logOutWithError.fulfilled, (state, action) => {
        state.user = null;
        state.token = null;
        state.isLoggedIn = false;
        state.isRefreshing = false;
      })
      .addCase(firstEnter.fulfilled, (state, action) => {
        state.user = action.payload.user;
        state.settings = action.payload.settings;
      })
      .addCase(changeSettings.fulfilled, (state, action) => {
        state.settings = action.payload;
      }),
});

export const authReducer = authSlice.reducer;
