import styled from 'styled-components';
import EllipsisText from 'react-ellipsis-text';

export const Item = styled.li`
  padding: 8px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 18px;
  &:not(:last-child) {
    margin-bottom: 16px;
  }
`;

export const SelectedItem = styled(Item)`
  background-color: #fff1e5;
  border-radius: 16px;
  /* &::before {
    content: '';
    position: absolute;
    height: 56px;
    width: 106%;
    background-color: #fff1e5;
    border-radius: 16px;
    z-index: -1;
  } */
`;

export const WrapperText = styled.div`
  display: block;
  margin-left: 16px;
`;

export const WrapSvgText = styled.div`
  display: flex;
  align-items: center;
`;

export const DateTransaction = styled.li`
  display: flex;
  justify-content: center;
  margin-top: 24px;
  margin-bottom: 16px;
  font-family: 'Kulim Park';
  font-size: 10px;
  line-height: 1.5;
  letter-spacing: 1.5px;
  color: #ff7601;
`;

export const CategoryStyle = styled(EllipsisText)`
  display: block;
  font-weight: 700;
  font-size: 12px;
  line-height: 1.5;
  letter-spacing: 1.5px;
  color: #52555f;

  padding-bottom: 1px;
`;

export const DescriptionStyle = styled(EllipsisText)`
  display: block;
  font-weight: 400;
  font-size: 10px;
  line-height: 1.5;
  letter-spacing: 1.5px;
  color: #52555f;
`;

export const LocationUser = styled.div`
  color: #52555f;
  font-size: 10px;
  line-height: 1.5;
  letter-spacing: 1.5px;
  margin-top: 4px;
`;
export const ImgLocation = styled.img``;

export const TextStyle = styled.p`
  font-weight: 400;
  font-size: 10px;
  line-height: 1.5;
  letter-spacing: 1.5px;
  color: ${p => p.theme.colors.accent};
  padding-right: 5px;
`;

export const TimeStyle = styled.p`
  font-size: 10px;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 1.5px;
  color: ${p => p.theme.colors.textprimary};
  display: inline;
`;

export const Sum = styled.p`
  display: inline;

  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  text-align: right;
`;

export const Plug = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 39px;
`;

export const PlugText = styled.p`
  text-align: center;
  color: #52555f;
  font-size: 12px;
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: 0.48px;
  margin-top: 88px;
`;

export const TableHeading = styled(PlugText)`
  margin-top: 0;
`;
