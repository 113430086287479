import styled from 'styled-components';

export const TitleWrapper = styled.section`
  width: 100%;
`;

export const Title = styled.h1`
  font-family: 'Paytone One';
  font-weight: 400;
  font-size: 32px;
  line-height: 1.5;
  letter-spacing: 0.64px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: capitalize;
  margin-bottom: 1px;
  color: #ff7601;
`;

export const Line = styled.p`
  width: calc(100% - 44px);
  height: 0px;
  margin-left: 58px;
  border: 1px solid #fff1e5;
`;

export const Signature = styled.h3`
  font-family: 'Kulim Park';
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.32px;
  text-align: right;
  color: #52555f;
`;
