import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Formik, Form, ErrorMessage } from 'formik';
import { AuthSocial } from '../AuthorizationSocial/AuthorizationSocial';
import { register, logIn } from '../../../redux/auth/authOperations';
import * as yup from 'yup';
import Notiflix from 'notiflix';
import Icons from 'images/allSvgFinTrack.svg';
import {
  BtnWrap,
  Custom,
  FormContainer,
  FormText,
  Input,
  Label,
  LinkPass,
  Pass,
  PrivacyText,
  PrivacyLink,
  PrivacyWrap,
  IconWrap,
  PasswordWrap,
  Checkbox,
} from './Form.styled';
import StyledButton from 'components/Buttons/Buttons.styled';
import { ModalPassReset } from 'components/Modal/ModalPassReset/ModalPassReset';

import { Loader } from 'components/Loader/Loader';
import { useTranslation } from 'react-i18next';

const shema = yup.object().shape({
  email: yup.string().required().email().max(255),
  password: yup.string().min(4).required(),
  checkbox: yup.boolean().oneOf([true], 'You must accept the privacy policy'),
});

const initialValues = {
  email: '',
  password: '',
};

export const AuthForm = ({ onConfirm, emailForResend, setEmailForResend }) => {
  const dispatch = useDispatch();
  const [button, setButton] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPasswordVisible, setPasswordVisibility] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isPrivacyPolicyAccepted, setIsPrivacyPolicyAccepted] = useState(false);
  const { t } = useTranslation();

  const handleClick = e => {
    switch (e.target.name) {
      case 'login':
        return setButton(true);
      case 'register':
        return setButton(false);

      default:
        return;
    }
  };

  const handleSubmit = async (values, { resetForm }) => {
    setIsLoading(true);
    try {
      if (!button) {
        if (isPrivacyPolicyAccepted) {
          const userData = {
            ...values,
            checked: isPrivacyPolicyAccepted,
          };
          await dispatch(register(userData));
        } else {
          Notiflix.Notify.warning('Privacy policy not confirmed');
        }
      } else {
        const userData = {
          ...values,
          checked: isPrivacyPolicyAccepted,
        };
        await dispatch(logIn(userData));
        resetForm();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleForgotPasswordClick = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const toggleCodeVisibility = () => {
    setPasswordVisibility(!isPasswordVisible);
  };

  const handleCheckboxChange = e => {
    setIsPrivacyPolicyAccepted(e.target.checked);
  };

  return (
    <FormContainer>
      {isLoading ? (
        <Loader />
      ) : (
        <Formik
          initialValues={initialValues}
          validationSchema={shema}
          onSubmit={handleSubmit}
        >
          <Form>
            <AuthSocial />
            <FormText>{t('authForm.descFormAuth')}</FormText>
            <label htmlFor="email">
              <Label>{t('authForm.email')}:</Label>
              <Input
                type="email"
                name="email"
                placeholder="your@email.com"
                autoComplete="off"
              />
              <ErrorMessage name="email" component={Custom} />
            </label>
            <PasswordWrap>
              <Pass htmlFor="password">
                <Label>{t('authForm.password')}:</Label>
                <Input
                  type={isPasswordVisible ? 'text' : 'password'}
                  name="password"
                  placeholder="Password"
                  autoComplete="off"
                />
                <IconWrap onClick={toggleCodeVisibility}>
                  {isPasswordVisible ? (
                    <svg
                      style={{
                        color: '#FF7601',
                        width: '16px',
                        height: '16px',
                        cursor: 'pointer',
                      }}
                    >
                      <use href={`${Icons}#icon-eye`}></use>
                    </svg>
                  ) : (
                    <svg
                      style={{
                        color: '#FF7601',
                        width: '16px',
                        height: '16px',
                        cursor: 'pointer',
                      }}
                    >
                      <use href={`${Icons}#icon-eye-crossed`}></use>
                    </svg>
                  )}
                </IconWrap>
                <ErrorMessage name="password" component={Custom} />
              </Pass>
            </PasswordWrap>
            <LinkPass onClick={handleForgotPasswordClick}>
              {t('authForm.forgotPassword')}?
            </LinkPass>
            {isModalOpen && (
              <ModalPassReset
                onClose={handleModalClose}
                onConfirm={onConfirm}
                emailForResend={emailForResend}
                setEmailForResend={setEmailForResend}
              />
            )}
            <PrivacyWrap>
              <Checkbox
                type="checkbox"
                name="checkbox"
                id="myCheckbox"
                checked={isPrivacyPolicyAccepted}
                onChange={handleCheckboxChange}
              />
              <label htmlFor="myCheckbox" />
              <PrivacyText>
                {t('authForm.privacyPolicy')}
                <PrivacyLink href="https://sites.google.com/view/privacypolicyforfintrack?usp=sharing">
                  {t('authForm.privacy')}
                </PrivacyLink>
              </PrivacyText>
            </PrivacyWrap>
            <BtnWrap>
              <StyledButton onClick={handleClick} name="login" type="orange">
                {t('authForm.buttonLogIn')}
              </StyledButton>
              <StyledButton onClick={handleClick} name="register" type="white">
                {t('authForm.buttonRegistration')}
              </StyledButton>
            </BtnWrap>
          </Form>
        </Formik>
      )}
    </FormContainer>
  );
};
