import { useSelector, useDispatch } from 'react-redux';
import Notiflix from 'notiflix';
import Icons from 'images/allSvgFinTrack.svg';
import { selectTokenDeadline } from 'redux/selectors';
import { deleteTransaction } from 'redux/transactions/transactionsOperations';
import { Button, ButtonsWrap } from './EditDelet.syled';
import { RefreshUser } from 'redux/auth/authOperations';
import { deleteReminder } from 'redux/reminders/remindersOperations';
import { deleteWallet } from 'redux/wallets/walletsOperations';
import { useTranslation } from 'react-i18next';
import { deleteDebt } from 'redux/debt/debtOperations';

export const EditDeletBtns = ({
  data,
  transactionId,
  handleEditClick,
  position,
  walletId,
  deleteType,
  handleEditWalletClick,
  paidStatusMarker,
  handleSkipClick,
  handleNullClick,
}) => {
  const dispatch = useDispatch();
  const deadline = useSelector(selectTokenDeadline);
  const { t } = useTranslation();

  const handleDelete = async e => {
    e.preventDefault();

    if (deadline) {
      if (Date.now() >= deadline) await dispatch(RefreshUser());
    }
    try {
      if (deleteType === 'reminder') {
        console.log('rem');
        await dispatch(deleteReminder({ transactionId }));
      } else if (deleteType === 'transaction') {
        console.log('tr');
        await dispatch(deleteTransaction({ transactionId }));
      } else if (deleteType === 'debt') {
        console.log('debt');

        await dispatch(deleteDebt({ debtsId: transactionId }));
        console.log('debt', transactionId);
      } else if (paidStatusMarker) {
        console.log('debt');
        await handleSkipClick(data);

        console.log('debt', transactionId);
      } else {
        console.log('wall');
        await dispatch(deleteWallet({ walletId }));
      }
      await dispatch(RefreshUser());

      Notiflix.Notify.success(!paidStatusMarker ? t('notify.editDellOne') : '');
    } catch (error) {
      Notiflix.Notify.failure(t('notify.editDellTwo'));
    }
  };

  const getPositionStyle = () => {
    if (position === 'wallet-edit-del') {
      return {
        position: 'absolute',
        top: '32px',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      };
    }

    return {
      position: 'absolute',
      top: '-31px',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    };
  };
  const styleButtons = () => {
    switch (paidStatusMarker || deleteType === 'debt') {
      case true:
        return {
          width: '24px',
          height: '24px',
          fill: '#FF7601',
        };
      case false:
        return {
          width: '20px',
          height: '20px',
          fill: '#FF7601',
        };
      default:
        return {
          width: '20px',
          height: '20px',
          fill: '#FF7601',
        };
    }
  };
  return (
    <>
      <ButtonsWrap style={getPositionStyle()}>
        {paidStatusMarker ? (
          <>
            <Button onClick={() => handleEditClick(data)}>
              <svg
                width={styleButtons().width}
                height={styleButtons().height}
                fill={styleButtons().fill}
              >
                <use href={`${Icons}#icon-greenCross`}></use>
              </svg>
            </Button>
            <Button onClick={() => handleSkipClick(data)}>
              <svg
                width={styleButtons().width}
                height={styleButtons().height}
                fill={styleButtons().fill}
              >
                <use href={`${Icons}#icon-redross`}></use>
              </svg>
            </Button>
            <Button onClick={() => handleNullClick(data)}>
              <svg
                width={styleButtons().width}
                height={styleButtons().height}
                fill={styleButtons().fill}
              >
                <use href={`${Icons}#icon-null`}></use>
              </svg>
            </Button>
          </>
        ) : (
          <>
            <Button
              onClick={() =>
                deleteType === 'wallet'
                  ? handleEditWalletClick({ walletId, data })
                  : handleEditClick(data)
              }
            >
              <svg
                width={styleButtons().width}
                height={styleButtons().height}
                fill={styleButtons().fill}
              >
                {deleteType === 'debt' ? (
                  <use href={`${Icons}#icon-info`}></use>
                ) : (
                  <use href={`${Icons}#icon-edit`}></use>
                )}
              </svg>
            </Button>
            <Button id={transactionId} onClick={handleDelete}>
              <svg width="24" height="24" fill={'#FF7601'}>
                <use href={`${Icons}#icon-trash`}></use>
              </svg>
            </Button>
          </>
        )}
      </ButtonsWrap>
    </>
  );
};
