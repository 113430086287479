import styled from 'styled-components';

export const HeaderStyled = styled.header`
  position: fixed;
  top: 0;
  z-index: 300;
  display: flex;
  align-items: center;
  width: 100%;
  height: 56px;
  padding: 8px 16px;
  background-color: ${p => p.theme.colors.main.white};
  box-shadow: 0px 4px 10px rgba(170, 178, 197, 0.25);
  box-sizing: border-box;
`;

export const HeaderWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 0;
`;

export const UserAvatar = styled.div`
  padding: 9px 12px;
  font-size: 12px;
  line-height: 1.17;
  font-weight: 700;
  color: ${p => p.theme.colors.text.text};
  background-color: #f5f6fa;
  border-radius: 50%;
`;

export const LogoutImg = styled.img`
  cursor: pointer;
`;

export const NavWraper = styled.nav`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const BalanceTitle = styled.p`
  color: #ff7601;
`;

export const BalanceWrap = styled.div`
  display: flex;
  align-items: center;
`;
