import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { Notify } from 'notiflix/build/notiflix-notify-aio';
import { getPeriod } from 'utils/getPeriod';

export const addTransaction = createAsyncThunk(
  'transactions/addTransaction',
  async (
    { date, description, category, sum, operation, place, icon, walletId },
    ThunkAPI
  ) => {
    try {
      const { data } = await axios.post('/transactions', {
        date,
        description,
        category,
        sum,
        operation,
        place,
        icon,
        walletId,
      });
      return data;
    } catch (e) {
      Notify.warning(e.response.data.message);
      return ThunkAPI.rejectWithValue(e.message);
    }
  }
);

export const editTransaction = createAsyncThunk(
  'transactions/editTransaction',
  async ({ transactionId, updatedData }, ThunkAPI) => {
    try {
      const { data } = await axios.put(
        `/transactions/${transactionId}`,
        updatedData
      );
      return data;
    } catch (e) {
      Notify.warning(e.response.data.message);
      return ThunkAPI.rejectWithValue(e.message);
    }
  }
);

export const deleteTransaction = createAsyncThunk(
  'transactions/deleteTransaction',
  async ({ transactionId }, ThunkAPI) => {
    try {
      const { data } = await axios.delete(`/transactions/${transactionId}`);
      return data;
    } catch (e) {
      Notify.warning(e.response.data.message);
      return ThunkAPI.rejectWithValue(e.message);
    }
  }
);

export const getTransactions = createAsyncThunk(

  'transactions/getTransactions',
  async ({ operation, dateRange }, ThunkAPI) => {
    try {
      const [start, end] = getPeriod(dateRange);
      const { data } = await axios.get(
        `/transactions/${operation.toLowerCase()}`,
        {
          params: {
            start,
            end,
          },
        }
      );
      return data;
    } catch (e) {
      Notify.warning(e.response.data.message || 'An error occurred');
      return ThunkAPI.rejectWithValue(e.message);
    }
  }
);

export const getTransactionsLast = createAsyncThunk(
  'transactions/getTransactionsLast',
  async ({ operation, startPeriod, endPeriod }, ThunkAPI) => {
    try {
      const [start, end] = getPeriod([startPeriod, endPeriod]);
      const { data } = await axios.get(
        `/transactions/${operation.toLowerCase()}`,
        {
          params: {
            start,
            end,
          },
        }
      );
      return data;
    } catch (e) {
      Notify.warning(e.response.data.message || 'An error occurred');
      return ThunkAPI.rejectWithValue(e.message);
    }
  }
);

export const getReport = createAsyncThunk(
  'transactions/getReport',
  async ({ operation, year, month }, ThunkAPI) => {
    try {
      const { data } = await axios.get(
        `/transactions/report/${operation}?year=${year}&month=${month}`
      );
      return data;
    } catch (e) {
      Notify.warning(e.response.data.message);
      return ThunkAPI.rejectWithValue(e.message);
    }
  }
);
