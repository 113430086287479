import { useState, useEffect } from 'react';
import Icons from 'images/allSvgFinTrack.svg';
import { ModalCategories } from 'components/Modal/ModalTransaction/Category/ModalCategory';
import {
  CategoryBtn,
  CategoryContainer,
  CategoryText,
  Text,
  SelectedCategoryBtn,
} from 'components/Modal/ModalTransaction/ModalTransaction.styled';
import { useTranslation } from 'react-i18next';
import { useTranslate } from 'hooks/useTranslate';

export const Category = ({
  icon,
  setIcon,
  setSelectedCategory,
  selectedCategory,
  transactionToEdit,
}) => {
  const { category } = useTranslate();
  const { t } = useTranslation();
  const categoryList = Object.values(category);
  const [categories, setCategories] = useState(categoryList.slice(0, 3));
  const [categoriesMore, setCategoriesMore] = useState(categoryList);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (selectedCategory) {
      const updatedCategories = [...categoriesMore];
      const clickedCategoryIndex = updatedCategories.findIndex(
        category => category.id === icon
      );
      if (clickedCategoryIndex !== -1) {
        const clickedCategory = updatedCategories.splice(
          clickedCategoryIndex,
          1
        )[0];

        updatedCategories.unshift(clickedCategory);
      }
      setCategories(updatedCategories.slice(0, 3));
    }
  }, [categoriesMore, icon, selectedCategory]);

  const toggleModal = () => {
    setModalOpen(prevState => !prevState);
  };

  const handleCategoryClick = (id, categoryName) => {
    const updatedCategories = [...categoriesMore];
    const clickedCategoryIndex = updatedCategories.findIndex(
      category => category.id === id
    );
    if (clickedCategoryIndex !== -1) {
      const clickedCategory = updatedCategories.splice(
        clickedCategoryIndex,
        1
      )[0];

      updatedCategories.unshift(clickedCategory);
    }
    setCategories(updatedCategories.slice(0, 3));
    setIcon(id);
    setSelectedCategory(id);
  };

  return (
    <CategoryContainer
      style={{ paddingLeft: '4px', paddingRight: '4px', marginBottom: '12px' }}
    >
      {categories.slice(0, 3).map(({ id, categoryName }) => (
        <li key={id}>
          {selectedCategory === id ? (
            <SelectedCategoryBtn
              type="button"
              onClick={() => handleCategoryClick(id, categoryName)}
            >
              <svg
                width="24"
                height="24"
                fill={'#FF7601'}
                style={{
                  marginBottom: '2px',
                  paddingLeft: '15px',
                  paddingRight: '15px',
                }}
              >
                <use href={`${Icons}#${id}`}></use>
              </svg>
              <CategoryText text={categoryName} length={9} />
            </SelectedCategoryBtn>
          ) : (
            <CategoryBtn
              type="button"
              onClick={() => handleCategoryClick(id, categoryName)}
            >
              <svg
                width="24"
                height="24"
                fill={'#FF7601'}
                style={{
                  marginBottom: '2px',
                  paddingLeft: '15px',
                  paddingRight: '15px',
                }}
              >
                <use href={`${Icons}#${id}`}></use>
              </svg>
              <CategoryText text={categoryName} length={9} />
            </CategoryBtn>
          )}
        </li>
      ))}
      <li>
        <CategoryBtn type="button" onClick={toggleModal}>
          <svg
            width="24"
            height="24"
            fill={'#FF7601'}
            style={{ marginBottom: 2 }}
          >
            <use href={`${Icons}#plus`} />
          </svg>
          <Text>{t('madalGeneral.more')}</Text>
        </CategoryBtn>
      </li>
      {modalOpen && (
        <ModalCategories
          categoriesMore={categoriesMore}
          setCategoriesMore={setCategoriesMore}
          setIcon={setIcon}
          onClose={toggleModal}
          setCategories={setCategories}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          transactionToEdit={transactionToEdit}
        />
      )}
    </CategoryContainer>
  );
};
