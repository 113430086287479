import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { createPortal } from 'react-dom';
import { useNavigate } from 'react-router-dom';

import {
  FormContainer,
  FormText,
  Input,
  Label,
  CloseBtn,
  ModalBackdrop,
  WrapPassw,
  IconWrap,
  ErrorMes,
} from './ModalPassRecovery.styled';
import {
  ConfirmBtn,
  BtnsContainer,
} from 'components/Modal/ModalFirstEnter/ModalFirstEnter.styled';
import { passwordRecovery } from 'redux/auth/authOperations';
import Icons from 'images/allSvgFinTrack.svg';
import { useTranslation } from 'react-i18next';

const modalRoot = document.querySelector('#modal-root');

export const ModalPassRecovery = ({ emailForResend }) => {
  const [newPassword, setNewPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [codeFromEmail, setCodeFromEmail] = useState('');

  const [isCodeVisible, setCodeVisibility] = useState(false);
  const [isNewPasswordVisible, setNewPasswordVisibility] = useState(false);
  const [isRepeatPasswordVisible, setRepeatPasswordVisibility] =
    useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  
  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    document.body.style.overflow = 'hidden';

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      document.body.style.overflow = 'unset';
    };
  });

  const handleKeyDown = evt => {
    if (evt.code === 'Escape') {
      navigate('/auth');
    }
  };

  const handleBackdropClick = evt => {
    if (evt.target === evt.currentTarget) {
      navigate('/auth');
    }
  };

  const clickClose = () => {
    navigate('/auth');
  };

  const handleEnterCode = e => {
    setCodeFromEmail(Number(e.target.value));
  };
  const handleChangePassword = e => {
    setNewPassword(e.target.value);
  };
  const handleRepeatPassword = e => {
    setRepeatPassword(e.target.value);
  };

  const isPasswordValid =
    newPassword === repeatPassword && newPassword.length >= 5;

  const onConfirm = async () => {
    console.log('password:', newPassword);
    if (!isPasswordValid) {
      setErrorMessage('Passwords do not match');
      return;
    }

    dispatch(
      passwordRecovery({
        password: newPassword,
        code: codeFromEmail,
        email: emailForResend,
      })
    )
      .then(() => {
        setNewPassword('');
        setRepeatPassword('');
        setCodeFromEmail('');
        setErrorMessage('');

        navigate('/auth');
      })
      .catch(error => {
        console.error('error during password recovery:', error);
      });
  };

  const toggleCodeVisibility = () => {
    setCodeVisibility(!isCodeVisible);
  };

  const toggleNewPasswordVisibility = () => {
    setNewPasswordVisibility(!isNewPasswordVisible);
  };

  const toggleRepeatPasswordVisibility = () => {
    setRepeatPasswordVisibility(!isRepeatPasswordVisible);
  };

  return createPortal(
    <ModalBackdrop onClick={handleBackdropClick}>
      <FormContainer>
        <CloseBtn onClick={clickClose} type="button">
          <svg width="24" height="24" fill={'#FF7601'}>
            <use href={`${Icons}#icon-close`}></use>
          </svg>
        </CloseBtn>
        <FormText>{t('modalPassRec.titleText')}</FormText>
        <WrapPassw>
          <Label>{t('modalPassRec.enterCode')}</Label>
          <Input
            type={isCodeVisible ? 'text' : 'password'}
            value={codeFromEmail}
            name="codeFromEmail"
            placeholder="12345Qwerty"
            autoComplete="off"
            onChange={handleEnterCode}
            style={{ marginBottom: '24px' }}
          />
          <IconWrap onClick={toggleCodeVisibility}>
            {isCodeVisible ? (
              <svg
                style={{
                  color: '#FF7601',
                  width: '16px',
                  height: '16px',
                  cursor: 'pointer',
                }}
              >
                <use href={`${Icons}#icon-eye`}></use>
              </svg>
            ) : (
              <svg
                style={{
                  color: '#FF7601',
                  width: '16px',
                  height: '16px',
                  cursor: 'pointer',
                }}
              >
                <use href={`${Icons}#icon-eye-crossed`}></use>
              </svg>
            )}
          </IconWrap>
        </WrapPassw>
        <WrapPassw>
          <Label>{t('modalPassRec.newPass')}</Label>
          <Input
            type={isNewPasswordVisible ? 'text' : 'password'}
            value={newPassword}
            name="newPassword"
            placeholder="12345Qwerty"
            autoComplete="off"
            onChange={handleChangePassword}
            style={{ marginBottom: '24px' }}
          />
          <IconWrap onClick={toggleNewPasswordVisibility}>
            {isNewPasswordVisible ? (
              <svg
                style={{
                  color: '#FF7601',
                  width: '16px',
                  height: '16px',
                  cursor: 'pointer',
                }}
              >
                <use href={`${Icons}#icon-eye`}></use>
              </svg>
            ) : (
              <svg
                style={{
                  color: '#FF7601',
                  width: '16px',
                  height: '16px',
                  cursor: 'pointer',
                }}
              >
                <use href={`${Icons}#icon-eye-crossed`}></use>
              </svg>
            )}
          </IconWrap>
        </WrapPassw>
        <WrapPassw>
          <Label>{t('modalPassRec.repeatNewPass')}</Label>
          <Input
            type={isRepeatPasswordVisible ? 'text' : 'password'}
            value={repeatPassword}
            name="repeatPassword"
            placeholder="12345Qwerty"
            autoComplete="off"
            onChange={handleRepeatPassword}
            style={{ marginBottom: '32px' }}
          />
          <IconWrap onClick={toggleRepeatPasswordVisibility}>
            {isRepeatPasswordVisible ? (
              <svg
                style={{
                  color: '#FF7601',
                  width: '16px',
                  height: '16px',
                  cursor: 'pointer',
                }}
              >
                <use href={`${Icons}#icon-eye`}></use>
              </svg>
            ) : (
              <svg
                style={{
                  color: '#FF7601',
                  width: '16px',
                  height: '16px',
                  cursor: 'pointer',
                }}
              >
                <use href={`${Icons}#icon-eye-crossed`}></use>
              </svg>
            )}
          </IconWrap>
        </WrapPassw>
        {errorMessage && (
          <ErrorMes style={{ color: 'red' }}>{errorMessage}</ErrorMes>
        )}
        <BtnsContainer>
          <ConfirmBtn onClick={onConfirm} type="button">
            {t('madalGeneral.confirm')}
          </ConfirmBtn>
        </BtnsContainer>
      </FormContainer>
    </ModalBackdrop>,
    modalRoot
  );
};
