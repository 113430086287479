import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  RemindersTitle,
  RemindersText,
  AddButton,
  RemindersItem,
  RemindersList,
  ReminderText,
  Container,
  ReminderCategory,
} from './Reminders.styled';
import Icons from 'images/allSvgFinTrack.svg';
import { ModalReminders } from 'components/Modal/ModalReminder/ModalReminder';
import { useTranslation } from 'react-i18next';
import { useTranslate } from 'hooks/useTranslate';

export const Reminders = ({ reminders }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const location = useLocation();
  const currentPage = location.pathname;
  const { category } = useTranslate();
  const { t } = useTranslation();
  const toggleModal = () => {
    setModalOpen(prevState => !prevState);
  };

  const formatDate = dateString => {
    const options = { day: '2-digit', month: '2-digit' };
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString('en-US', options);
    const [day, month] = formattedDate.split('/');
    return `${day}.${month}`;
  };

  return (
    <>
      <Container>
        <RemindersTitle>{t('remindersTrsl.remindersText')}</RemindersTitle>
        {currentPage === '/home' && (
          <>
            {reminders && reminders.length === 0 ? (
              <AddButton onClick={toggleModal}>
                <svg
                  width={24}
                  height={24}
                  fill={'#FF7601'}
                  style={{ marginRight: 16 }}
                >
                  <use href={`${Icons}#plus`} />
                </svg>
                <RemindersText>{t('remindersTrsl.addRmind')}</RemindersText>
              </AddButton>
            ) : (
              <RemindersList>
                {reminders && reminders.length >= 1 && reminders.length < 3 ? (
                  <>
                    {reminders &&
                      Array.isArray(reminders) &&
                      reminders
                        .filter(reminder => reminder !== undefined)
                        .map(({ _id, dateOfPayment, icon }) => (
                          <RemindersItem key={_id}>
                            <svg
                              width={24}
                              height={24}
                              fill={'#FF7601'}
                              style={{ marginBottom: '2px' }}
                            >
                              <use href={`${Icons}#${icon}`} />
                            </svg>
                            <ReminderCategory
                              text={category[icon].categoryName}
                              length={15}
                            />
                            <ReminderText>
                              {formatDate(dateOfPayment)}
                            </ReminderText>
                          </RemindersItem>
                        ))}
                    <RemindersItem onClick={toggleModal}>
                      <svg
                        width={24}
                        height={24}
                        fill={'#FF7601'}
                        style={{ marginBottom: '2px' }}
                      >
                        <use href={`${Icons}#plus`} />
                      </svg>
                      <ReminderText style={{ textAlign: 'center' }}>
                        {t('remindersTrsl.addRmind')}
                      </ReminderText>
                    </RemindersItem>
                  </>
                ) : (
                  <>
                    {reminders &&
                      Array.isArray(reminders) &&
                      reminders
                        .filter(reminder => reminder !== undefined)
                        .slice(0, 3)
                        .map(({ _id, dateOfPayment, icon }) => (
                          <RemindersItem key={_id}>
                            <svg
                              width={24}
                              height={24}
                              fill={'#FF7601'}
                              style={{ marginBottom: '2px' }}
                            >
                              <use href={`${Icons}#${icon}`} />
                            </svg>
                            <ReminderCategory
                              text={category[icon].categoryName}
                              length={15}
                            />
                            <ReminderText>
                              {formatDate(dateOfPayment)}
                            </ReminderText>
                          </RemindersItem>
                        ))}
                  </>
                )}
              </RemindersList>
            )}
          </>
        )}
        {currentPage === '/planning' && (
          <AddButton onClick={toggleModal}>
            <svg
              width={24}
              height={24}
              fill={'#FF7601'}
              style={{ marginRight: 16 }}
            >
              <use href={`${Icons}#plus`} />
            </svg>
            <RemindersText>{t('remindersTrsl.addRmind')}</RemindersText>
          </AddButton>
        )}
      </Container>
      {modalOpen && <ModalReminders onClose={toggleModal} />}
    </>
  );
};
