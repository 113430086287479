import React, { useEffect, useRef, useState } from 'react';

import { ReactComponent as Icon } from '../../../images/burgerMenu.svg';

import { BurgerMenuList } from './BurgerMenuList/BurgerMenuList';

import { BurgerButton, BurgerContainer } from './Burger.styled';
import { ModaLogout } from 'components/Modal/ModalLogout/ModalLogout';
import { useLogout } from 'hooks/logoutHook';

export const BurgerMenu = ({ activeIndex, setActiveIndex }) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);

  const handleToggleMenu = () => {
    setIsOpen(prevState => !prevState);
  };

  const handleClickOutside = event => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const { modalOpen, toggleModal, handleClick } = useLogout(handleToggleMenu);

  return (
    <div ref={ref}>
      <BurgerButton
        style={
          isOpen
            ? { transform: 'rotate(-90deg)' }
            : { transform: 'rotate(0deg)' }
        }
        onClick={handleToggleMenu}
      >
        <Icon />
      </BurgerButton>
      {isOpen && (
        <BurgerContainer>
          <BurgerMenuList
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
            onClose={handleToggleMenu}
            toggleModal={toggleModal}
          />
        </BurgerContainer>
      )}
      {modalOpen && (
        <ModaLogout onConfirm={handleClick} onClose={toggleModal} />
      )}
    </div>
  );
};