import Icons from 'images/allSvgFinTrack.svg';
import { Switcher, OperationText, BtnArrow } from '../ModalTransaction.styled';
import { useTranslate } from 'hooks/useTranslate';

export const SwitchOperation = ({ operation, setOperation }) => {
  const { operationsSwitch } = useTranslate();
  const operations = operationsSwitch;
  const currentIndex = operations.indexOf(operation);

  const handlePrevClick = () => {
    const prevIndex =
      (currentIndex - 1 + operations.length) % operations.length;
    setOperation(operations[prevIndex]);
  };

  const handleNextClick = () => {
    const nextIndex = (currentIndex + 1) % operations.length;
    setOperation(operations[nextIndex]);
  };

  return (
    <Switcher>
      <BtnArrow type="button" onClick={handlePrevClick}>
        <svg width="24" height="24" fill={'#FF7601'}>
          <use href={`${Icons}#icon-arrow-left`}></use>
        </svg>
      </BtnArrow>
      <OperationText>{operation && operation.label}</OperationText>
      <BtnArrow type="button" onClick={handleNextClick}>
        <svg width="24" height="24" fill={'#FF7601'}>
          <use href={`${Icons}#icon-arrow-right`}></use>
        </svg>
      </BtnArrow>
    </Switcher>
  );
};
