export const getPeriod = dateRange => {
  let [start, end] = dateRange;

  const startDay = start.getDate();
  const startMonth = start.getMonth();
  const startYear = start.getFullYear();

  start = `${startYear}-${startMonth + 1}-${startDay}`;

  const endDay = end.getDate();
  const endMonth = end.getMonth();
  const endYear = end.getFullYear();

  end = `${endYear}-${endMonth + 1}-${endDay}`;

  return [start, end];
};
