import React, { useEffect } from 'react';
import { Button } from './Calculator.styled';

export const Buttons = ({
  inputHandler,
  clearInput,
  backspace,
  calculateAns,
}) => {
  useEffect(() => {
    const handleKeyPress = event => {
      if (event.key === 'Enter') {
        event.preventDefault();
        document.getElementById('equalbtn').click();
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  return (
    <>
      <Button onClick={clearInput}>C</Button>
      <Button onClick={inputHandler}>÷</Button>
      <Button onClick={inputHandler}>x</Button>
      <Button onClick={backspace}>⌫</Button>
      <Button onClick={inputHandler}>7</Button>
      <Button onClick={inputHandler}>8</Button>
      <Button onClick={inputHandler}>9</Button>
      <Button onClick={inputHandler}>-</Button>
      <Button onClick={inputHandler}>4</Button>
      <Button onClick={inputHandler}>5</Button>
      <Button onClick={inputHandler}>6</Button>
      <Button onClick={inputHandler}>+</Button>
      <Button onClick={inputHandler}>1</Button>
      <Button onClick={inputHandler}>2</Button>
      <Button onClick={inputHandler}>3</Button>
      <Button onClick={inputHandler}>%</Button>
      <Button onClick={inputHandler}>0</Button>
      <Button onClick={inputHandler}>00</Button>
      <Button onClick={inputHandler}>.</Button>
      <Button id="equalbtn" onClick={calculateAns}>
        =
      </Button>
    </>
  );
};
