import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getDebt } from 'redux/debt/debtOperations';
import { getReminders } from 'redux/reminders/remindersOperations';

export const useGetPlanningDebtsRemainders = dateRange => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getReminders({ dateRange })).catch(error => {
      console.error('Failed to get reminders:', error);
    });
    dispatch(getDebt({ dateRange })).catch(error => {
      console.error('Failed to get debt:', error);
    });
  }, [dateRange, dispatch]);
};
