import styled from 'styled-components';

export const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.25);
`;
export const ModalContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 48px 40px 60px;
  background-color: #fff;
  border-radius: 30px;
  box-shadow: 10px 10px 30px rgba(82, 85, 95, 0.4);
  transform: translate(-50%, -50%);
`;
export const CloseBtn = styled.button`
  position: absolute;
  right: 20px;
  top: 20px;
  background-color: transparent;
  border: none;
  cursor: pointer;

  transition: 250ms;

  :hover {
    transform: scale(1.2);
  }
`;
export const TitleText = styled.p`
  margin-bottom: 24px;
  font-size: 12px;
  line-height: 1.5;
  text-align: center;
  color: #52555f;
`;
export const BtnsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 14px;
`;
export const Button = styled.button`
  width: 95px;
  height: 42px;
  border: none;
  border-radius: 16px;
  cursor: pointer;
  font-size: 12px;
  line-height: 1.5;
  text-align: center;
  letter-spacing: 0.02em;
  box-shadow: 4px 4px 16px 0px rgba(170, 178, 197, 0.25);
  transition-property: transform;
  transition-duration: 250ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

  :hover {
    transform: scale(1.1);
  }
`;
export const ConfirmBtn = styled(Button)`
  color: #fff;
  background-color: #ff751d;
`;
export const CancelBtn = styled(Button)`
  color: #52555f;
  background-color: #fff;
`;
