import React from 'react';

import { useDispatch } from 'react-redux';
import Icons from 'images/allSvgFinTrack.svg';
import {
  IconDescr,
  IconWraper,
  SearchWrap,
} from 'components/Calendar/Calendar.styled';
import { setStatusSearch } from 'redux/search/searchSlice';
import { useTranslation } from 'react-i18next';

export const Search = ({ searchValue, setSearchValue }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const onChange = event => {
    const inputValue = event.currentTarget.value;
    const isNumber = /^\d+$/.test(inputValue);

    setSearchValue(inputValue);

    if (isNumber) {
      dispatch(setStatusSearch(Number(inputValue)));
    } else {
      dispatch(setStatusSearch(inputValue));
    }
  };

  return (
    <SearchWrap>
      <input type="text" value={searchValue} onChange={onChange} />
      <IconWraper>
        <svg width="24" height="24" fill={'#FF7601'}>
          <use href={`${Icons}#icon-search`}></use>
        </svg>
        <IconDescr>{t('search.iconDescr')}</IconDescr>
      </IconWraper>
    </SearchWrap>
  );
};
