import {
  CloseBtn,
  CloseBtnWpar,
  ModalBackdrop,
  ModalContainer,
} from '../ModalTransaction/ModalTransaction.styled';
import { createPortal } from 'react-dom';

import Icons from 'images/allSvgFinTrack.svg';
import { Transaction } from 'components/Transaction/Transaction';

import { useSelector } from 'react-redux';
import { selectDebt } from 'redux/selectors';

const ModalPaidStatus = ({ onClose, handleBackdropClick, debt }) => {
  const debts = useSelector(selectDebt);

  const modalRoot = document.querySelector('#modal-root');

  const currentDebt = debts.find(item => item._id === debt._id);

  const paymentByMonth = currentDebt.paymentsByMonth;

  const addIconFieldDebt = arr => {
    return arr.map(obj => {
      const { amount, ...rest } = obj;
      const icon = () => {
        // eslint-disable-next-line default-case
        switch (obj.paid) {
          case null:
            return 'icon-empty';
          case true:
            return 'icon-greenCross';
          case false:
            return 'icon-redross';
        }
      };
      return {
        ...rest,
        sum: amount,
        icon: icon(),
      };
    });
  };

  return createPortal(
    <ModalBackdrop onClick={handleBackdropClick}>
      <ModalContainer style={{ overflowY: 'scroll' }}>
        <CloseBtnWpar>
          <CloseBtn onClick={onClose} type="button">
            <svg width="24" height="24" fill={'#FF7601'}>
              <use href={`${Icons}#icon-arrow-down`}></use>
            </svg>
          </CloseBtn>
        </CloseBtnWpar>

        <Transaction
          transactions={addIconFieldDebt(paymentByMonth)}
          allTransactions={false}
          tableHeadingSelect={false}
          deleteType={'debt'}
          paidStatusMarker={true}
          debtParent={currentDebt}
          iconDebt={currentDebt.icon}
          onClose={onClose}
        />
      </ModalContainer>
    </ModalBackdrop>,
    modalRoot
  );
};

export default ModalPaidStatus;
