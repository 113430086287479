import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import Notiflix from 'notiflix';
import {
  BtnWrap,
  CloseBtn,
  CloseBtnWpar,
  ModalBackdrop,
  ModalContainer,
  Text,
} from './ModalReminder.styled';
import Icons from 'images/allSvgFinTrack.svg';
import StyledButton from '../../Buttons/Buttons.styled';
import { Category } from 'components/Modal/ModalTransaction/Category/Category';
import { Description } from './Description/Description';
import { Calculator } from '../ModalTransaction/Calculator/Calculator';
import { Display } from '../ModalTransaction/Calculator/Display';
import { selectTokenDeadline } from 'redux/selectors';
import { RefreshUser } from 'redux/auth/authOperations';
import { addReminder, editReminder } from 'redux/reminders/remindersOperations';
import { CalendarWithClock } from 'components/Calendar/Calendar';
import { useTranslation } from 'react-i18next';

const modalRoot = document.querySelector('#modal-root');

export const ModalReminders = ({
  onClose,
  onChangeTagInput,
  transactionToEdit,
}) => {
  const transaction = transactionToEdit || {};
  const [icon, setIcon] = useState(transaction?.icon || '');
  const [dateOfPayment, setdateOfPayment] = useState(
    transaction?.dateOfPayment
      ? new Date(transaction.dateOfPayment)
      : new Date()
  );
  const [description, setDescription] = useState(
    transaction?.description || ''
  );
  const [selectedCategory, setSelectedCategory] = useState(
    transaction?.icon || null
  );
  const [openCalculator, setOpenCalculator] = useState(false);
  const [input, setInput] = useState(transaction?.sum || '');
  const [answer, setAnswer] = useState(transaction?.sum || '');
  const dispatch = useDispatch();
  const deadline = useSelector(selectTokenDeadline);
  const indentation = '254px';
  let sum = Number(answer) || Number(input);
  const { t } = useTranslation();
  
  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    document.body.style.overflow = 'hidden';
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      document.body.style.overflow = 'unset';
    };
  });

  const handleKeyDown = evt => {
    if (evt.code === 'Escape') {
      onClose();
    }
  };

  const handleBackdropClick = evt => {
    if (evt.target === evt.currentTarget) {
      onClose();
    }
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (description === '' || sum === '' || selectedCategory === '') {
      Notiflix.Notify.failure('Please fill in all fields.');
      return;
    }
    if (deadline) {
      if (Date.now() >= deadline) dispatch(RefreshUser());
    }

    try {
      if (!transactionToEdit) {
        dispatch(
          addReminder({
            description,
            sum,
            dateOfPayment,
            icon,
            regularPayment: true,
          })
        );
        Notiflix.Notify.success(t('notify.modalRemindersOne'));
      } else {
        const { _id, owner, createdAt, updatedAt, ...editedReminder } = {
          ...transaction,
          dateOfPayment,
          description,
          sum: Number(answer) || Number(input),
          icon,
        };
        dispatch(
          editReminder({
            transactionId: transaction._id,
            updatedData: editedReminder,
          })
        );
        Notiflix.Notify.success(t('notify.modalRemindersTwo'));
      }
      setDescription('');
      setdateOfPayment(new Date());
      setInput('');
      setAnswer('');
      dispatch(RefreshUser());
      onClose();
    } catch (error) {
      Notiflix.Notify.failure(t('notify.modalRemindersTree'));
    }
  };

  const handleClear = () => {
    setDescription('');
    setdateOfPayment(new Date());
    setIcon('');
    setDescription('');
    setSelectedCategory(null);
    setAnswer('');
    setInput('');
  };

  const onChangeDate = dateOfPayment => {
    setdateOfPayment(dateOfPayment);
  };

  return createPortal(
    <ModalBackdrop onClick={handleBackdropClick}>
      <ModalContainer>
        <CloseBtnWpar>
          <CloseBtn onClick={onClose} type="button">
            <svg width="24" height="24" fill={'#FF7601'}>
              <use href={`${Icons}#icon-arrow-down`}></use>
            </svg>
          </CloseBtn>
        </CloseBtnWpar>
        <form onSubmit={handleSubmit}>
          <Category
            setIcon={setIcon}
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
            transactionToEdit={transactionToEdit}
          />
          <Description
            setDescription={setDescription}
            description={description}
          />
          <Display
            openCalculator={openCalculator}
            setOpenCalculator={setOpenCalculator}
            input={input}
            setInput={setInput}
            answer={answer}
            setAnswer={setAnswer}
          />
          {openCalculator && (
            <Calculator
              indentation={indentation}
              input={input}
              setInput={setInput}
              answer={answer}
              setAnswer={setAnswer}
              value={sum}
              onChange={onChangeTagInput}
              setOpenCalculator={setOpenCalculator}
            />
          )}
          <Text>{t('modalReminder.date')}</Text>
          <CalendarWithClock
            value={dateOfPayment}
            changeValue={setdateOfPayment}
            onChange={onChangeDate}
          />
        </form>
        <BtnWrap>
          <StyledButton type="submit" onClick={handleSubmit} name="orange">
            {t('madalGeneral.save')}
          </StyledButton>
          <StyledButton onClick={handleClear} name="white" type="button">
            {t('madalGeneral.clean')}
          </StyledButton>
        </BtnWrap>
      </ModalContainer>
    </ModalBackdrop>,
    modalRoot
  );
};
