import styled from 'styled-components';
import EllipsisText from 'react-ellipsis-text';

export const Container = styled.div`
  box-sizing: border-box;
  margin-top: 40px;
`;
export const RemindersTitle = styled.h2`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.64px;
  color: #ff7601;
  margin-bottom: 24px;
`;
export const RemindersText = styled.p`
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.48px;
  color: #ff751d;
`;
export const AddButton = styled.button`
  display: flex;
  width: 100%;
  height: 80px;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  box-shadow: 4px 4px 16px 0px rgba(170, 178, 197, 0.25);
  border-radius: 30px;
  border: none;
`;
export const RemindersItem = styled.li`
  display: flex;
  width: 80px;
  height: 80px;
  background: #ffffff;
  box-shadow: 2px 4px 20px 0px rgba(170, 178, 197, 0.25);
  border-radius: 30px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;
export const RemindersList = styled.ul`
  display: flex;
  justify-content: space-around;
  gap: 24px;
  width: 100%;
`;
export const ReminderText = styled.p`
  font-size: 10px;
  line-height: 1.5;
  letter-spacing: 0.4px;
  color: #52555f;
`;
export const ReminderCategory = styled(EllipsisText)`
  font-size: 10px;
  line-height: 1.5;
  letter-spacing: 0.4px;
  color: #52555f;
`;