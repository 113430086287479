import React from 'react';
import { Buttons } from './Buttons';
import { evaluate, round } from 'mathjs';
import { Container, BtnClose, BtnCloseWrap, Wrap } from './Calculator.styled';
import { useTranslation } from 'react-i18next';

export const Calculator = ({
  indentation,
  input,
  setInput,
  answer,
  setAnswer,
  setOpenCalculator,
}) => {
  const { t } = useTranslation();
  
  const inputHandler = event => {
    event.preventDefault();
    setOpenCalculator(true);
    if (answer === 'Invalid Input!!') return;
    let val = event.target.innerText;

    if (val === 'x2') val = '^2';
    else if (val === 'x3') val = '^3';
    else if (val === '3√') val = '^(1÷3)';
    else if (val === 'log') val = 'log(';

    let str = input + val;
    if (str.length > 14) return;

    if (answer !== '') {
      setInput(answer + val);
      setAnswer('');
    } else setInput(str);
  };

  const clearInput = event => {
    event.preventDefault();
    setInput('');
    setAnswer('');
  };

  const checkBracketBalanced = expr => {
    let stack = [];
    for (let i = 0; i < expr.length; i++) {
      let x = expr[i];
      if (x === '(') {
        stack.push(x);
        continue;
      }
      if (x === ')') {
        if (stack.length === 0) return false;
        else stack.pop();
      }
    }
    return stack.length === 0;
  };

  const calculateAns = event => {
    event.preventDefault();
    if (input === '') return;
    let result = 0;
    let finalexpression = input;
    finalexpression = finalexpression.replaceAll('x', '*');
    finalexpression = finalexpression.replaceAll('÷', '/');

    let noSqrt = input.match(/√[0-9]+/gi);

    if (noSqrt !== null) {
      let evalSqrt = input;
      for (let i = 0; i < noSqrt.length; i++) {
        evalSqrt = evalSqrt.replace(
          noSqrt[i],
          `sqrt(${noSqrt[i].substring(1)})`
        );
      }
      finalexpression = evalSqrt;
    }

    try {
      if (!checkBracketBalanced(finalexpression)) {
        const errorMessage = { message: 'Brackets are not balanced!' };
        throw errorMessage;
      }
      result = evaluate(finalexpression);
    } catch (error) {
      result =
        error.message === 'Brackets are not balanced!'
          ? 'Brackets are not balanced!'
          : 'Invalid Input!!';
    }
    isNaN(result) ? setAnswer(result) : setAnswer(round(result, 3));
  };

  const backspace = event => {
    event.preventDefault();
    if (answer !== '') {
      setInput(answer.toString().slice(0, -1));
      setAnswer('');
    } else setInput(prev => prev.slice(0, -1));
  };

  return (
    <Wrap indentation={indentation}>
      <Container>
        <Buttons
          inputHandler={inputHandler}
          clearInput={clearInput}
          backspace={backspace}
          calculateAns={calculateAns}
        />
      </Container>
      <BtnCloseWrap>
        <BtnClose onClick={() => setOpenCalculator(false)}>
          {t('madalGeneral.close')}
        </BtnClose>
      </BtnCloseWrap>
    </Wrap>
  );
};
