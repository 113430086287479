import { createSlice } from '@reduxjs/toolkit';

import {
  newWallet,
  deleteWallet,
  getWallets,
  editWallet,
} from './walletsOperations';

const initialState = { entities: [], isLoading: false, error: null };

export const WalletSlice = createSlice({
  name: 'wallet',
  initialState,
  extraReducers: builder =>
    builder
      .addCase(newWallet.pending, state => {
        state.isLoading = true;
      })
      .addCase(newWallet.fulfilled, (state, action) => {
        state.isLoading = false;
        state.entities = [...state.entities, action.payload];
      })
      .addCase(newWallet.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(deleteWallet.pending, state => {
        state.isLoading = true;
      })
      .addCase(deleteWallet.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.entities = state.entities.filter(
          item => item._id !== action.payload.id
        );
      })
      .addCase(deleteWallet.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(getWallets.pending, state => {
        state.isLoading = true;
      })
      .addCase(getWallets.fulfilled, (state, action) => {
        state.entities = action.payload;
        state.isLoading = false;
      })
      .addCase(getWallets.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(editWallet.pending, state => {
        state.isLoading = true;
      })
      .addCase(editWallet.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        const index = state.entities.findIndex(
          item => item._id === action.payload.id
        );
        if (index !== -1) {
          state.entities[index] = action.payload;
        }
      })
      .addCase(editWallet.rejected, (state, action) => {
        state.error = action.payload;
      }),
});
export const walletReducer = WalletSlice.reducer;
