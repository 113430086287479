import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-calendar/dist/Calendar.css';
import {
  IconDescr,
  IconWraper,
  BtnArrow,
  Switcher,
  Wrap,
  LineWraper,
  ButtonCalendar,
  Buttons,
  Title,
} from 'components/Calendar/Calendar.styled';
import Icons from 'images/allSvgFinTrack.svg';
import verticalline from 'images/line-vertical.svg';
import { useTranslation } from 'react-i18next';
import { useTranslate } from 'hooks/useTranslate';

export const CalendarReport = ({
  dateRange,
  setDateRange,
  title,
  setTitle,
}) => {
  const [startDate, endDate] = dateRange;
  const [isOpen, setIsOpen] = useState(false);
  const { titles } = useTranslate();
  const { t } = useTranslation();

  useEffect(() => {
    if (isOpen) setTitle(5);

    const changePeriod = title => {
      const date = new Date();
      switch (title) {
        case 1:
          setDateRange([new Date(date - 6 * 24 * 60 * 60 * 1000), date]);
          break;
        case 2:
          setDateRange([new Date(date - 29 * 24 * 60 * 60 * 1000), date]);
          break;
        case 3:
          setDateRange([new Date(date - 89 * 24 * 60 * 60 * 1000), date]);
          break;
        case 4:
          setDateRange([new Date(date - 179 * 24 * 60 * 60 * 1000), date]);
          break;
        case 5:
          // setDateRange([null, null]);
          break;
        default:
          console.log('Invalid subscription type');
          return;
      }
    };

    changePeriod(title);
  }, [isOpen, setDateRange, setTitle, title]);

  const openFromIcon = () => {
    setIsOpen(!isOpen);
  };

  const TITLES = titles;

  const changeTitle = op => {
    switch (op) {
      case 'inc':
        if (title - 1 < 1) {
          setTitle(5);
          break;
        }
        setTitle(title - 1);
        break;
      case 'dec':
        if (title + 1 > 5) {
          setTitle(1);
          break;
        }
        setTitle(title + 1);
        break;
      default:
        console.log('Invalid subscription type');
        return;
    }
  };

  return (
    <Wrap>
      <IconWraper>
        <ButtonCalendar type="button" onClick={openFromIcon}>
          <svg width="24" height="24" fill={'#FF7601'}>
            <use href={`${Icons}#icon-calendar`}></use>
          </svg>
          <IconDescr>{t('calendar.iconDescr')}</IconDescr>
        </ButtonCalendar>
      </IconWraper>
      <LineWraper>
        <img src={verticalline} alt={verticalline} />
      </LineWraper>
      <Switcher>
        <BtnArrow type="button" onClick={() => changeTitle('inc')}>
          <svg width="24" height="24" fill={'#FF7601'}>
            <use href={`${Icons}#icon-arrow-left`}></use>
          </svg>
        </BtnArrow>
        <DatePicker
          showPopperArrow={false}
          maxDate={new Date()}
          open={isOpen}
          selectsRange={true}
          startDate={title === 5 && startDate}
          endDate={title === 5 && endDate}
          shouldCloseOnSelect={false}
          dateFormat="dd.MM.yy"
          readOnly
          onChange={update => {
            setDateRange(update);
          }}
          disabledKeyboardNavigation
        >
          <div>
            <Buttons
              onClick={() => {
                setDateRange([null, null]);
                setIsOpen(false);
              }}
            >
              {t('calendar.btnCansel')}
            </Buttons>
            <Buttons
              onClick={() => {
                setIsOpen(false);
              }}
            >
              {t('calendar.btnOk')}
            </Buttons>
          </div>
        </DatePicker>
        {/* {Boolean(startDate) || <Title>{`${TITLES[title - 1]}`}</Title>} */}
        {(title !== 5 || Boolean(!startDate)) && (
          <Title>{`${TITLES[title - 1]}`}</Title>
        )}
        <BtnArrow type="button" onClick={() => changeTitle('dec')}>
          <svg width="24" height="24" fill={'#FF7601'}>
            <use href={`${Icons}#icon-arrow-right`}></use>
          </svg>
        </BtnArrow>
      </Switcher>
    </Wrap>
  );
};
