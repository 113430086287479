import styled from 'styled-components';

export const ModalBackdrop = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 2;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ModalContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  max-height: 80%;
  overflow-y: auto;
  padding: 0px 24px 24px 24px;

  background-color: #ffffff;
  box-sizing: border-box;
  box-shadow: 2px 8px 80px rgba(170, 178, 197, 0.25);
  border-radius: 30px 30px 0px 0px;
`;

export const CloseBtnWpar = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 17px;
`;

export const CloseBtn = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;

  transition: 250ms;

  :hover {
    transform: scale(1.2);
  }
`;

export const BtnWrap = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-top: 24px;
`;

export const CategoryContainer = styled.ul`
  display: flex;
  justify-content: space-between;
  margin-bottom: 22px;
`;

export const CategoryBtn = styled.button`
  width: 64px;
  height: 64px;
  border: none;
  background-color: transparent;
  border-radius: 16px;

  cursor: pointer;
  :hover {
    background-color: #fff1e5;
    border-radius: 16px;
  }
  :focus {
    background-color: #fff1e5;
    border-radius: 16px;
  }
  :active {
    background-color: #fff1e5;
    border-radius: 16px;
  }
`;

export const CategoryText = styled.p`
  color: #52555f;
  font-family: 'Kulim Park';
  font-size: 12px;
  line-height: 1.5;
  letter-spacing: 0.04em;
`;

export const DescriptionContainer = styled.div`
  margin-bottom: 16px;
`;

export const Text = styled.p`
  font-family: 'Kulim Park';
  font-size: 12px;
  line-height: 1.5;
  letter-spacing: 0.04em;
  color: #52555f;
  margin-bottom: 4px;
`;

export const DescriptionInput = styled.input`
  display: block;
  width: 100%;
  height: 40px;
  background: #fff1e5;
  border-radius: 30px;
  border: none;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.02em;
  color: #52555f;
  text-indent: 12px;
  ::placeholder {
    font-family: 'Kulim Park';
    font-size: 12px;
    line-height: 1.5;
    letter-spacing: 0.04em;
    color: #a6abb9;
  }
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 40px;
  height: 40px;
  border: 1px solid #fff1e5;
  border-radius: 20px;
  background-color: transparent;
  font-family: 'Roboto';
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.02em;
  color: #ff7601;
  margin-right: 8px;
  cursor: pointer;
  :hover {
    background-color: #fff1e5;
  }
  :focus {
    background-color: #fff1e5;
  }
  :active {
    background-color: #fff1e5;
  }
`;
export const AmountInput = styled.input`
  width: 116px;
  background: #fff1e5;
  border-radius: 20px;
  padding: 8px 16px;
  border: none;
  font-family: 'Kulim Park';
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.02em;
  color: #ff7601;
  box-sizing: border-box;

  ::placeholder {
    color: #a6abb9;
  }
`;
export const AmountContainer = styled.div`
  display: flex;
  margin-bottom: 16px;
`;
export const WarningMessage = styled.p`
  position: absolute;
  color: red;
  font-size: 8px;
  line-height: 1.5;
  letter-spacing: 0.04em;
  margin-top: 4px;
`;

export const SvgContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 40px;
  height: 40px;
  border: 1px solid #fff1e5;
  border-radius: 20px;
  background-color: transparent;

  margin-right: 8px;
`;
export const DateInput = styled.input`
  width: 112px;
  height: 40px;
  background: #fff1e5;
  border-radius: 20px;
  border: none;
  text-indent: 12px;
  font-family: 'Kulim Park';
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.02em;
  color: #52555f;
  text-indent: 12px;
  ::placeholder {
    font-family: 'Kulim Park';
    font-size: 12px;
    line-height: 1.5;
    letter-spacing: 0.04em;
    color: #a6abb9;
  }
`;

export const CheckedText = styled.label`
  width: 120px;
  font-family: 'Kulim Park';
  font-size: 10px;
  line-height: 1.5;
  letter-spacing: 0.04em;
  color: #52555f;
  align-items: center;
  display: flex;
  cursor: pointer;
  ::before {
    content: ' ';
    width: 10px;
    height: 10px;
    border: 1px solid #ff7601;
    border-radius: 2px;
    margin-right: 12px;
    cursor: pointer;
  }
  input:checked + &::before {
    display: flex;
    content: '✔';
    justify-content: center;
    text-align: center;
    font-size: 8px;
    color: #ff7601;
  }
`;

export const InputCheckbox = styled.input`
  position: absolute;
  white-space: nowrap;
  width: 1px;
  height: 1px;
  overflow: hidden;
  border: 0;
  padding: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  margin: -1px;
`;
export const Label = styled.label`
  font-size: 12px;
  line-height: 1.5;
  letter-spacing: 0.04em;
  color: #52555f;
  margin-bottom: 4px;
`;