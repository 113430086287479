import { useDispatch, useSelector } from 'react-redux';
import { selectAllWallet, selectUserCurrency } from 'redux/selectors';
import { BalanceTitle, BalanceWrap } from './Header.styled';
import { useEffect } from 'react';
import { getWallets } from 'redux/wallets/walletsOperations';

export const Balance = data => {
  return data.reduce((acc, item) => acc + item.balance, 0);
};

export const DisplayBalance = () => {
  const dispatch = useDispatch();
  const allWallets = useSelector(selectAllWallet);
  const currencySymbol = useSelector(selectUserCurrency);
  useEffect(() => {
    dispatch(getWallets());
  }, [dispatch]);
  
  return (
    <BalanceWrap>
      <BalanceTitle>
        {currencySymbol} {Balance(allWallets)}
      </BalanceTitle>
    </BalanceWrap>
  );
};
