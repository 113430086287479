import { useTranslation } from 'react-i18next';
import { Input, Label } from '../ModalTransaction/ModalTransaction.styled';

export const NameDescriptionComponent = ({
  placeholder,
  value,
  onChange,
  name,
  id,
}) => {
  const { t } = useTranslation();
  return (
    <div>
      <Label>
        {id === 'description'
          ? t('madalGeneral.description')
          : t('madalGeneral.name')}
        <Input
          type="text"
          value={value}
          name={name}
          id={id}
          autoComplete="off"
          placeholder={placeholder}
          // required
          onChange={onChange}
        />
      </Label>
    </div>
  );
};
