import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: auto auto auto auto;
  align-items: center;
`;
export const DisplayS = styled.div`
  display: flex;
  margin-bottom: 9px;
  align-items: center;
  justify-content: space-around;
`;
export const ButtonCur = styled.button`
  border: 1px solid #fff1e5;
  background-color: #ffff;
  width: 40px;
  height: 40px;
  border-radius: 16px;
`;
export const Input = styled.input`
  color: #ff7601;
  border: 0px;
  font-size: 16px;
  letter-spacing: 1.5px;
  background: #fff1e5;
  border-radius: 16px;
  border: none;
  margin-left: 8px;
  width: 100%;
  height: 40px;
  padding-left: 16px;
  ::placeholder {
    color: #a6abb9;
  }
`;
export const Button = styled.button`
  width: 40px;
  height: 28px;

  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  cursor: pointer;
  border: 1px solid #fff1e5;
  border-radius: 16px;
  background: none;
  color: #ff7601;
  margin: 12px;
  user-select: none;

  &:hover {
    background-color: #ff7601;
    color: white;
    transition: background-color 0.3s ease;
    border-radius: 16px;
  }
  &:touched {
    background-color: #ff7601;
    color: white;
    transition: background-color 0.3s ease;
    border-radius: 16px;
  }
`;
export const BtnClose = styled.button`
  width: 116px;
  height: 44px;
  border: 1px solid #ff7601;
  border-radius: 16px;
  background-color: #ff7601;
  color: #ffffff;
`;
export const BtnCloseWrap = styled.div`
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  justify-content: center;
`;
export const Wrap = styled.div`
  position: absolute;
  z-index: 4;
  top: ${p => p.indentation};
  right: 14px;
  width: calc(100% - 48px);
  background-color: #ffffff;

  box-shadow: 2px 4px 20px rgba(170, 178, 197, 0.25);
  border-radius: 30px;
  padding: 4px 8px;
`;
