import { Label } from '../ModalTransaction/ModalTransaction.styled';
import { SliderContainer, SliderInput, SliderValue } from './ModalDebt.styled';

import { AmountComponent } from './AmountComponent';

export const SliderPayments = ({ amount, setCount, count }) => {
  const handleChange = event => {
    setCount(event.target.value);
  };

  const percentage = ((count - 1) / (12 - 1)) * 100;

  const pay = Math.ceil(amount / count);

  return (
    <>
      <Label>Mouth of Payment</Label>
      <SliderContainer>
        <SliderInput
          type="range"
          min="1"
          max="12"
          value={count}
          onChange={handleChange}
        />
        <SliderValue value={percentage}>{count}</SliderValue>
      </SliderContainer>

      <AmountComponent text={'Mounthly Payment'} amount={pay} />
    </>
  );
};
