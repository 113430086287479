import { createSlice } from '@reduxjs/toolkit';
import {
  addReminder,
  getReminders,
  deleteReminder,
  editReminder,
} from './remindersOperations';

const initialState = {
  isLoading: false,
  error: null,
  items: [],
};

const handlePending = state => {
  state.isLoading = true;
};

const handleError = (state, action) => {
  state.isLoading = false;
  state.error = action.payload || 'An error occurred';
};
const handleFulfilled = state => {
  state.isLoading = false;
  state.error = null;
};

const remindersSlice = createSlice({
  name: 'reminder',
  initialState,
  extraReducers: builder =>
    builder
      .addCase(addReminder.pending, handlePending)
      .addCase(addReminder.fulfilled, (state, action) => {
        handleFulfilled(state);
        if (Array.isArray(state.items)) {
          state.items.push(action.payload);
        } else {
          state.items = [action.payload];
        }
      })
      .addCase(addReminder.rejected, handleError)
      .addCase(getReminders.pending, state => {
        state.isLoading = true;
      })
      .addCase(getReminders.fulfilled, (state, { payload }) => {
        state.items = payload;
        state.error = null;
        state.isLoading = false;
      })
      .addCase(getReminders.rejected, (state, action) => {
        state.error = action.error.message;
        state.isLoading = false;
      })
      .addCase(deleteReminder.pending, handlePending)
      .addCase(deleteReminder.fulfilled, (state, action) => {
        handleFulfilled(state);
        state.items = state.items.filter(
          item => item._id !== action.payload._id
        );
      })
      .addCase(deleteReminder.rejected, handleError)
      .addCase(editReminder.pending, handlePending)
      .addCase(editReminder.fulfilled, (state, action) => {
        handleFulfilled(state);
        const index = state.items.findIndex(
          item => item._id === action.payload._id
        );
        if (index !== -1) {
          state.items[index] = action.payload;
        }
      })
      .addCase(editReminder.rejected, handleError),
});

export const remindersReducer = remindersSlice.reducer;