import {
  AuthTitle,
  SocialBtn,
  SocialBtnWrap,
  Text,
} from 'components/AuthorizationForm/AuthorizationSocial/AuthorizationSocial.styled';
import { useTranslation } from 'react-i18next';
import google from 'images/google.svg';

export const AuthSocial = () => {
  const { t } = useTranslation();
  return (
    <div>
      <AuthTitle>{t('authForm.descGoogleAuth')}:</AuthTitle>
      <SocialBtnWrap>
        <SocialBtn href="https://fintrack.onrender.com/auth/google">
          <img
            src={google}
            alt="google icon"
            style={{ width: '20px', height: '20px', marginRight: '10px' }}
          />
          <Text>Google</Text>
        </SocialBtn>
      </SocialBtnWrap>
    </div>
  );
};
