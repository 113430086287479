import { useEffect } from 'react';
import { createPortal } from 'react-dom';
import {
  BtnsContainer,
  CancelBtn,
  CloseBtn,
  ConfirmBtn,
  ModalBackdrop,
  ModalContainer,
  TitleText,
} from './ModalLogout.styled';
import Icons from 'images/allSvgFinTrack.svg';
import { useTranslation } from 'react-i18next';

const modalRoot = document.querySelector('#modal-root');

export const ModaLogout = ({ onConfirm, onClose }) => {
  const { t } = useTranslation();
  
  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    document.body.style.overflow = 'hidden';

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      document.body.style.overflow = 'unset';
    };
  });

  const handleKeyDown = evt => {
    if (evt.code === 'Escape') {
      onClose();
    }
  };

  const handleBackdropClick = evt => {
    if (evt.target === evt.currentTarget) {
      onClose();
    }
  };

  return createPortal(
    <ModalBackdrop onClick={handleBackdropClick}>
      <ModalContainer>
        <CloseBtn onClick={onClose} type="button">
          <svg width="24" height="24" fill={'#FF7601'}>
            <use href={`${Icons}#icon-close`}></use>
          </svg>
        </CloseBtn>
        <TitleText>{t('modalLogOut.askMsg')}</TitleText>
        <BtnsContainer>
          <ConfirmBtn onClick={onConfirm} type="button">
            {t('modalLogOut.logOut')}
          </ConfirmBtn>
          <CancelBtn onClick={onClose} type="button">
            {t('modalLogOut.no')}
          </CancelBtn>
        </BtnsContainer>
      </ModalContainer>
    </ModalBackdrop>,
    modalRoot
  );
};
