import styled from 'styled-components';
import Select from 'react-select';
import EllipsisText from 'react-ellipsis-text';

export const ModalBackdrop = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 2;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const ModalContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  max-height: 80%; 
  overflow-y: auto; 
  padding: 0px 24px 24px 24px;

  background-color: #ffffff;
  box-sizing: border-box;
  box-shadow: 2px 8px 80px rgba(170, 178, 197, 0.25);
  border-radius: 30px 30px 0px 0px;
`;
export const CategoriesWrap = styled.div`
  display: grid;
  grid-template-columns: auto auto auto auto;
  justify-items: center;
`;
export const CategoryText = styled(EllipsisText)`
  color: #52555f;
  font-family: Kulim Park;
  font-size: 12px;
  line-height: 1.5;
  letter-spacing: 0.04em;
`;
export const CategoryBtn = styled.button`
  width: 64px;
  height: 64px;
  border: none;
  background-color: transparent;
 
  cursor: pointer;
  :hover {
    background-color: #fff1e5;
    border-radius: 16px;
  }
  :focus {
    background-color: #fff1e5;
    border-radius: 16px;
  }
  :active {
    background-color: #fff1e5;
    border-radius: 16px;
  }
`;
export const SelectedCategoryBtn = styled(CategoryBtn)`
  background-color: #fff1e5;
  border-radius: 16px;
`;
export const CategoryContainer = styled.ul`
  display: flex;
  justify-content: space-between;
  margin-bottom: 22px;
`;
export const Text = styled.p`
  font-family: 'Kulim Park';
  font-size: 12px;
  line-height: 1.5;
  letter-spacing: 0.04em;
  color: #52555f;
  margin-bottom: 4px;
`;
export const CloseBtnWpar = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 17px;
`;
export const CloseBtn = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;

  transition: 250ms;

  :hover {
    transform: scale(1.2);
  }
`;
export const BtnWrap = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-top: 24px;
`
export const Input = styled.input`
  color: #52555f;
  border: 0px;
  font-size: 12px;
  line-height: 1.5;
  background: #fff1e5;
  border-radius: 30px;
  border: none;
  width: 100%;
  padding: 11px 12px;
  box-sizing: border-box;
  ::placeholder {
    color: #a6abb9;
  }
`;
export const Label = styled.label`
  font-size: 12px;
  line-height: 1.5;
  letter-spacing: 0.04em;
  color: #52555f;
  margin-bottom: 4px;
`;
export const ImgLocation = styled.img`
  position: absolute;
  top: 29px;
  left: 12px;
`
export const Switcher = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 57px;
  background: #ffffff;
  box-shadow: 2px 4px 20px rgba(170, 178, 197, 0.25);
  border-radius: 30px;
  margin-bottom: 12px;
`;
export const OperationText = styled.p`
  font-weight: 700;
  font-size: 12px;
  line-height: 1.5;
  color: #ff7601;
  margin-left: 32px;
  margin-right: 32px;
`;
export const BtnArrow = styled.button`
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: transparent;
  border: none;
  transition: transform 300ms cubic-bezier(0.4, 0, 0.2, 1);

  &:focus {
    transform: scale(0.9);
  }
`;
/////////////////////////Select
export const WalletSelect = styled(Select)`
  .wallet-select__control {
    background: #ffffff;
    border: 1px solid #fff1e5;
    border-radius: 16px;
    justify-content: center;
    margin-bottom: 16px;
    margin-top: 16px;
    box-shadow: none;
  }
  .wallet-select__control--menu-is-open {
    border-radius: 16px 16px 0px 0px;
  }
  .wallet-select__indicator-separator {
    display: none;
  }
  .wallet-select__placeholder {
    font-family: 'Kulim Park';
    font-size: 12px;
    line-height: 1.5;
    letter-spacing: 0.04em;
    color: #ff7601;
    text-align: center;
  }
  .css-tj5bde-Svg {
    fill: #ff7601;
    stroke: #ff7601;
    margin-right: 16px;
  }
  .css-1xc3v61-indicatorContainer {
    padding: 0;
  }
  .wallet-select__input-container {
    padding-top: 11px;
    padding-bottom: 11px;
  }
  .css-13cymwt-control:hover {
    border-color: #fff1e5;
  }
  .wallet-select__single-value {
    display: flex;
    justify-content: center;
    font-family: 'Kulim Park';
    font-size: 12px;
    line-height: 1.5;
    letter-spacing: 0.04em;
    color: #ff7601;
    align-items: center;
    padding: 0px;
    margin: 0px;
  }
  .wallet-select__menu {
    margin: 0px;
    padding: 0px;
    border-radius: 0px 0px 16px 16px;
    box-shadow: 4px 4px 16px rgba(170, 178, 197, 0.25);
  }
  .wallet-select__menu-list {
    margin: 0px;
    padding: 0px;
  }
  .wallet-select__option {
    display: flex;
    align-items: center;
    font-family: 'Kulim Park';
    font-size: 12px;
    line-height: 1.5;
    letter-spacing: 0.04em;
    color: #ff7601;
  }
  .wallet-select__option--is-focused {
    background: #fff1e5;
  }
  .wallet-select__option--is-selected {
    background: #fff1e5;
  }
`;
export const TransferWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  margin-top: 7px;
  margin-bottom: 16px;
`;
export const WalletTransferSelect = styled(WalletSelect)`
`