import { useTranslation } from 'react-i18next';
import { Text } from '../ModalTransaction/ModalTransaction.styled';
import {
  AmountContainer,
  AmountInput,
  Button,
  WarningMessage,
} from '../ModalReminder/ModalReminder.styled';

export const AmountComponent = ({ handleAmountChange, amount, text }) => {
  const { t } = useTranslation();
  console.log('amountComp', amount);
  return (
    <div>
      <Text>{text ? text : t('madalGeneral.amount')}</Text>
      <AmountContainer>
        <Button>€</Button>
        <div>
          <AmountInput
            type="text"
            maxLength={10}
            onChange={handleAmountChange ? handleAmountChange : () => {}}
            placeholder="0"
            value={amount}
          />
          {isNaN(amount) ? (
            <WarningMessage>{t('madalGeneral.pleaseEntNumb')}</WarningMessage>
          ) : (
            <></>
          )}
        </div>
      </AmountContainer>
    </div>
  );
};
