import { Field } from 'formik';
import styled from 'styled-components';

export const FormContainer = styled.div`
  padding: 24px;
  margin-top: 76px;
  background: #ffffff;
  box-shadow: 2px 4px 20px 0px rgba(170, 178, 197, 0.25);
  border-radius: 30px;
`;

export const FormText = styled.p`
  margin-top: 40px;

  font-family: 'Kulim Park';
  font-size: 12px;
  line-height: 1.5;
  letter-spacing: 0.48px;

  color: #52555f;
`;

export const Label = styled.p`
  margin-bottom: 4px;
  margin-top: 24px;
  font-family: 'Kulim Park';
  font-weight: 400;
  font-size: 10px;
  line-height: 1.5;
  letter-spacing: 0.4px;

  color: #52555f;
`;

export const Input = styled(Field)`
  position: relative;
  width: 100%;
  height: 40px;
  padding-left: 12px;
  background: #fff1e5;
  border: none;
  border-radius: 30px;
  box-sizing: border-box;

  font-family: 'Kulim Park';
  font-size: 12px;
  line-height: 1.5;
  letter-spacing: 0.48px;

  ::placeholder {
    color: #a6abb9;
  }
`;

export const Pass = styled.label`
  display: block;
  margin-bottom: 8px;
`;

export const LinkPass = styled.a`
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 24px;

  font-family: 'Kulim Park';
  font-size: 12px;
  line-height: 1.5;
  letter-spacing: 0.4px;
  text-decoration-line: underline;

  color: #52555f;
`;

export const BtnWrap = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Custom = styled.div`
  position: absolute;
  color: tomato;
  font-size: 10px;
`;

export const PrivacyText = styled.p`
  font-family: Kulim Park;
  font-size: 10px;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.4px;
  color: #a6abb9;
  margin-left: 5px;
`;

export const PrivacyLink = styled.a`
  margin-left: 3px;
  font-weight: 600;
  color: #a6abb9;
`;

export const PrivacyWrap = styled.div`
  display: flex;
  margin-bottom: 24px;
`;

export const PasswordWrap = styled.div`
  position: relative;
`;

export const IconWrap = styled.div`
  position: absolute;
  top: 31px;
  right: 12px;
`;

export const Checkbox = styled.input`
  display: none;
  & + label {
    position: relative;
    padding-left: 15px;
    cursor: pointer;
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 2px;
      width: 10px;
      height: 10px;
      border: 1px solid #ff7601;
      background-color: #fff;
      border-radius: 2px;
    }
    &:after {
      content: '';
      position: absolute;
      left: 2px;
      top: 5px;
      width: 6px;
      height: 3px;
      border-left: 1px solid #ff7601;
      border-bottom: 1px solid #ff7601;
      transform: rotate(-45deg);
      opacity: 0;
    }
  }
  &:checked + label:after {
    opacity: 1;
  }
`;
