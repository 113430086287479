import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';

import { authReducer } from './auth/authSlice';
import { transactionsReducer } from './transactions/transactionsSlice';
import { dateReducer } from 'redux/calendar/calendarSlice';
import { searchReducer } from './search/searchSlice';
import { pagesStateReducer } from './pagesState/pagesStateSlice';
import { walletReducer } from './wallets/walletSlice';
import { remindersReducer } from './reminders/remindersSlice';
import { debtsReducer } from './debt/debtSlice';
import currencyReducer from './currency/currencySlise';

const authPersistConfig = {
  key: 'auth',
  storage,
  whitelist: ['token', 'settings'],
};
const walletPersistConfig = {
  key: 'wallet',
  storage,
};
const debtPersistConfig = {
  key: 'debt',
  storage,
  whitelist: ['items'],
};
const currencyPersistConfig = {
  key: 'currency',
  storage,
};
export const store = configureStore({
  reducer: {
    auth: persistReducer(authPersistConfig, authReducer),
    date: dateReducer,
    transactions: transactionsReducer,
    search: searchReducer,
    statePage: pagesStateReducer,
    wallet: persistReducer(walletPersistConfig, walletReducer),
    reminders: remindersReducer,
    debt: persistReducer(debtPersistConfig, debtsReducer),
    currency: persistReducer(currencyPersistConfig, currencyReducer),
  },
  middleware(getDefaultMiddleware) {
    return getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    });
  },
});
export const persistor = persistStore(store);
