import styled from 'styled-components';

export const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.25);
`;

export const FormContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 288px;
  padding: 24px;
  background-color: #fff;
  border-radius: 30px;
  box-shadow: 2px 4px 20px rgba(170, 178, 197, 0.25);
  transform: translate(-50%, -50%);
  box-sizing: border-box;
`;

export const FormText = styled.p`
  font-size: 12px;
  line-height: 1.5;
  color: #52555f;
  margin-bottom: 40px;
  letter-spacing: 0.04em;
`;

export const Label = styled.p`
  font-size: 10px;
  line-height: 1.5;
  letter-spacing: 0.04em;
  color: #52555f;
  margin-bottom: 4px;
`;

export const Input = styled.input`
  color: #52555f;
  border: none;
  font-size: 12px;
  line-height: 1.5;
  background: #fff1e5;
  border-radius: 30px;
  border: 1px solid transparent;
  width: 100%;
  padding: 11px 16px;
  box-sizing: border-box;
  ::placeholder {
    color: #a6abb9;
  }
  :focus {
    outline: #ff7601;
    border: 1px solid #ff7601;
  }
`;

export const BtnWrapper = styled.div`
  margin-top: 32px;
  display: flex;
  justify-content: center;
`;

export const CloseBtn = styled.button`
  position: absolute;
  right: 16px;
  top: 16px;
  background-color: transparent;
  border: none;
  cursor: pointer;

  transition: 250ms;

  :hover {
    transform: scale(1.2);
  }
`;

export const WrapPassw = styled.div`
  position: relative;
`;

export const IconWrap = styled.div`
  position: absolute;
  top: 29px;
  right: 12px;
`;

export const ErrorMes = styled.span`
  display: flex;
  font-size: 14px;
  justify-content: center;
  margin-bottom: 10px;
`;

export const Message = styled.p`
  font-size: 10px;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: 0.04em;
  margin-bottom: 16px;
`;

export const SmallIconWrap = styled.div`
  position: absolute;
  top: 116px;
  right: 36px;
`