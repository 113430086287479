import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { selectIsLoggedIn } from 'redux/selectors';
import {
  HeaderStyled,
  HeaderWrap,
  NavWraper,
} from './Header.styled';
import logo from 'images/logo.svg';

import { DisplayBalance } from 'components/Header/Balance';

import { BurgerMenu } from './BurgerMenu/Burger';

export const Header = ({ activeIndex, setActiveIndex }) => {
  const isLoggedIn = useSelector(selectIsLoggedIn);

  return (
    <>
      <HeaderStyled>
        <HeaderWrap>
          <Link to="/home">
            <img
              src={logo}
              alt="logo"
            />
          </Link>
          {isLoggedIn && (
            <>
              <DisplayBalance />
              <NavWraper>{/* <UserAvatar>U</UserAvatar> */}</NavWraper>
            </>
          )}
        </HeaderWrap>
        {isLoggedIn && (
          <BurgerMenu
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
          />
        )}
      </HeaderStyled>
    </>
  );
};
